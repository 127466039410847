import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from 'react-router-dom';

const Selected = () => {
  return (
    <React.Fragment>
      <Row className="align-items-center">
        <Col lg={8}>
          <div className="mb-4 mb-lg-0">
            <h6 className="mb-0"> Mis Ofertas de Empleo </h6>
          </div>
        </Col>
        <Col lg={4}>
          <div className="candidate-list-widgets">
            <Row>
              {/* <Link to="/bookmarkjobpost" className="btn btn-primary">
                Añadir
              </Link> */}
              <Link className="btn btn-primary" to="/bookmarkjobpost" onClick={localStorage.removeItem("job_id")}>
                Añadir
              </Link>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Selected;
