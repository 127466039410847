import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//Import Images
// import JobDetailImage from "../../../assets/images/job-detail.jpg";

// import { Link } from "react-router-dom";
import { getJobs_id2, getCompany2 } from "../../../services/userservice";
import { API_URL } from "../../../config";
import companyImg from "../../../assets/images/companyImg.jpg";

const JobDetailsDescription = () => {
  var job = localStorage.getItem("jobs");
  var rol = localStorage.getItem("rol");
  
    
  
  const [jobs, setJobs] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [modality, setModality] = useState("");
  const [img, setImg] = useState("");
  const [compania,setCompania] =useState(""); 
  
  
  
  useEffect(() => {
    getJobs_id2(job)
    .then((data) =>{ setJobs(data);})
    .catch((error) => console.error(error));
  }, [job]);

  useEffect(() => {
    setTitle(jobs.title);
    setDescription(jobs.description);
    setSalary(jobs.salary);
    setModality(jobs.modality);
    setCompania(jobs.company_id);
  }, [jobs,rol]);
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if(compania === undefined || compania.length === 0){
          return
        }
        getCompany2(compania)
        .then(companyData => {
          setImg(companyData.photo);
        })
        .catch(error => console.error(error));

      } catch (error) {
        console.error(error);
      }
    };
  
    fetchData();
  }, [compania, rol]);
  
      
  
    
  


  return (
    <React.Fragment>
      <Card className="job-detail overflow-hidden pt-5">
        <div>
          {/* <img
            src={JobDetailImage}
            alt=""
            className="img-fluid"
          /> */}
          <div className="job-details-compnay-profile">
            <img
              src={img ? `${API_URL}download2/${img}` : ''}
              alt=""
              className="img-fluid rounded-3"
              style={{ width: "75px", height: "75px", objectFit:"cover", marginTop:"2%" }}
              onError={(e) => { e.target.src = companyImg; }}
            />
          </div>
        </div>
        <CardBody className="p-4">
          <div>
            <Row>
              <Col md={8}>
                <h5 className="mb-1">{title}</h5>
                {/* <ul className="list-inline text-muted mb-0">
                  <li className="list-inline-item">
                    <i className="mdi mdi-account"></i> 8 Vacantes
                  </li>
                  <li className="list-inline-item text-warning review-rating">
                    <span className="badge bg-warning">4.8</span>{" "}
                    <i className="mdi mdi-star align-middle"></i>
                    <i className="mdi mdi-star align-middle"></i>
                    <i className="mdi mdi-star align-middle"></i>
                    <i className="mdi mdi-star align-middle"></i>
                    <i className="mdi mdi-star-half-full align-middle"></i>
                  </li>
                </ul> */}
              </Col>
              {/* <Col lg={4}>
                <ul className="list-inline mb-0 text-lg-end mt-3 mt-lg-0">
                  <li className="list-inline-item">
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-heart-alt"></i>
                      </Link>
                    </div>
                  </li>
                  <li className="list-inline-item">
                    <div className="favorite-icon">
                      <Link to="#">
                        <i className="uil uil-setting"></i>
                      </Link>
                    </div>
                  </li>
                </ul>
              </Col> */}
            </Row>
          </div>

          <div className="mt-4">
            <Row className="g-2">
              {/* <Col lg={3}>
                <div className="border rounded-start p-3">
                  <p className="text-muted mb-0 fs-13">Experiencia</p>
                  <p className="fw-medium fs-15 mb-0">Minimo 1 año</p>
                </div>
              </Col> */}
              <Col lg={6}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Tipo de Empleo</p>
                  <p className="fw-medium mb-0">{modality}</p>
                </div>
              </Col>
              {/* <Col lg={3}>
                <div className="border p-3">
                  <p className="text-muted fs-13 mb-0">Posicion</p>
                  <p className="fw-medium mb-0">Senior</p>
                </div>
              </Col> */}
              <Col lg={6}>
                <div className="border rounded-end p-3">
                  <p className="text-muted fs-13 mb-0">Oferta Salarial</p>
                  <p className="fw-medium mb-0">{salary}/ Mes</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="mt-4">
            <h5 className="mb-3">Descripcion</h5>
            <div className="job-detail-desc">
              <p className="text-muted mb-0">{description}</p>
            </div>
          </div>

          {/* <div className="mt-4">
            <h5 className="mb-3">Responsabilidades</h5>
            <div className="job-detail-desc mt-2">
              <p className="text-muted">
                Como desarrollador web, trabajará dentro de una entrega de
                productos Equipo fusionado con talento de UX, ingeniería,
                productos y datos
              </p>
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Tener un conocimiento
                  sólido de actividades comerciales.
                </li>
                <li>
                  <i className="uil uil-circle"></i> Cree una web de última
                  generación aplicaciones con un enfoque en el lado del cliente
                </li>
                <li>
                  <i className="uil uil-circle"></i> Trabaja en múltiples
                  proyectos a la vez, y cumplir constantemente con los plazos
                  del borrador
                </li>
                <li>
                  <i className="uil uil-circle"></i> Estar graduado o estar
                  actualmente en cualquier año de estudio
                </li>
                <li>
                  <i className="uil uil-circle"></i> Revisar el trabajo de
                  anteriores diseñadores para crear una estética unificada para
                  nuestra marca materiales
                </li>
              </ul>
            </div>
          </div> */}

          <div className="mt-4">
            <h5 className="mb-3">Modalidad</h5>
            <div className="job-detail-desc mt-2">
              <span>
                <strong>{modality}</strong>
              </span>
              {/* <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> B.C.A / M.C.A bajo
                  Curso universitario nacional completo.
                </li>
                <li>
                  <i className="uil uil-circle"></i> 3 o más años de
                  experiencia en diseño profesional
                </li>
                <li>
                  <i className="uil uil-circle"></i> Estar graduado o
                  estar actualmente en cualquier año de estudio
                </li>
                <li>
                  <i className="uil uil-circle"></i> Grado avanzado o
                  experiencia equivalente en diseño gráfico y web
                </li>
              </ul> */}
            </div>
          </div>

          {/* <div className="mt-4">
            <h5 className="mb-3">Skill & Expericia</h5>
            <div className="job-details-desc">
              <ul className="job-detail-list list-unstyled mb-0 text-muted">
                <li>
                  <i className="uil uil-circle"></i> Comprensión del diseño
                  clave Principal
                </li>
                <li>
                  <i className="uil uil-circle"></i> Dominio de HTML, CSS,
                  Bootstrap
                </li>
                <li>
                  <i className="uil uil-circle"></i> Wordpress: 1 Año
                  (Requerido)
                </li>
                <li>
                  <i className="uil uil-circle"></i> Experiencia en diseño y
                  desarrollo de sitios web de diseño receptivo
                </li>
                <li>
                  <i className="uil uil-circle"></i> Diseño Web: 1 Año
                  (Preferiblemente)
                </li>
              </ul>
              <div className="mt-4 d-flex flex-wrap align-items-start gap-1">
                <span className="badge bg-primary">PHP</span>
                <span className="badge bg-primary">JS</span>
                <span className="badge bg-primary">Marketing</span>
                <span className="badge bg-primary">REACT</span>
                <span className="badge bg-primary">PHOTOSHOP</span>
              </div>
            </div>
          </div> */}

          {/* <div className="mt-4 pt-3">
            <ul className="list-inline mb-0">
              <li className="list-inline-item mt-1">Comparte este trabajo:</li>
              <li className="list-inline-item mt-1">
                <Link to="www.facebook.com" className="btn btn-primary btn-hover">
                  <i className="uil uil-facebook-f"></i> Facebook
                </Link>
              </li> */}
              {/* <li className="list-inline-item mt-1">
                <Link to="l" className="btn btn-danger btn-hover">
                  <i className="uil uil-google"></i> Google+
                </Link>
              </li> */}
              {/* <li className="list-inline-item mt-1">
                <Link to="www.linkeding.com" className="btn btn-success btn-hover">
                  <i className="uil uil-linkedin-alt"></i> linkedin
                </Link>
              </li>
            </ul>
          </div> */}
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default JobDetailsDescription;
