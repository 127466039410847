import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Card, Col, Input, Row, CardBody } from "reactstrap";
import { useNavigate } from "react-router-dom";
import lightLogo from "../../assets/images/Avanza_Empleo.png";
import darkLogo from "../../assets/images/Avanza_Empleo.png";
import privacyPolitic from "../../assets/images/POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS AVANZA EMPLEO S.A.S .pdf";
import signUpImage from "../../assets/images/auth/sign-up.png";
import { Form } from "react-bootstrap";
import { postUser } from "../../services/userservice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
  const [selectedOption, setSelectedOption] = useState("default");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rol, setRol] = useState("");
  const navigate = useNavigate();

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setRol(value);  
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedOption === "default") {
      toast.error("Por favor, selecciona una opción válida.");
      return;
    }

    const passwordValidation = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!passwordValidation.test(password)) {
      toast.error("La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un número");
      return;
    }

    try {
      await postUser(username, email, password, rol);
      toast.success("Registro exitoso. Redirigiendo a la página de inicio de sesión...");
      localStorage.setItem("registro", "registro");
      setTimeout(() => {
        navigate('/signin'); 
      }, 3000); // Redirigir después de 3 segundos
    } catch (err) {
      console.log(err);
      toast.error("Error al crear usuario");
    }
  };

  document.title = "Avanza Empleo | Registrarme";
  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="align-items-center">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                                style={{ width: "180px" }}
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                                style={{ width: "180px" }}
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signUpImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 text-white">
                            <div className="w-100">
                              <div className="text-center">
                                <h5>Avancemos</h5>
                                <p className="text-white-70">
                                  Registra la información de tu hoja de vida y comienza a postular en las ofertas laborales que tenemos para ti; tenemos vacantes que están en búsqueda de nuevos talentos.
                                </p>
                              </div>
                              <Form action="/" className="auth-form" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                  <label htmlFor="usernameInput" className="form-label">
                                    Usuario / Nombre
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    required
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    id="usernameInput"
                                    placeholder="Digite su nombre o usuario"
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="emailInput" className="form-label">
                                    Correo electrónico
                                  </label>
                                  <Input
                                    type="email"
                                    className="form-control"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    id="emailInput"
                                    placeholder="Digite su e-mail"
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="passwordInput" className="form-label">
                                    Contraseña
                                  </label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Digite su contraseña"
                                  />
                                </div>
                                <div className="mb-3">
                                  <select 
                                    value={selectedOption} 
                                    onChange={handleSelectChange}
                                    style={{width:"100%",height:"40px",borderRadius:"5px",border:"1px solid #ced4da",padding:"5px"}}
                                  >
                                    <option value="default">Selecciona una opción</option>
                                    <option value="1">Soy Empresa</option>
                                    <option value="2">Estoy buscando empleo</option>
                                  </select>
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <Input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                      required
                                    />
                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                    Acepto la {" "}
                                      <a 
                                        href={privacyPolitic} 
                                        download 
                                        className="text-white text-decoration-underline"
                                      >
                                        política de privacidad y términos y condiciones.
                                      </a>
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button type="submit" className="btn btn-white btn-hover w-100">
                                    Registrarse
                                  </button>
                                </div>
                              </Form>
                              <div className="mt-3 text-center">
                                <p className="mb-0">
                                  Ya tengo cuenta{" "}
                                  <Link to="/signin" className="fw-medium text-white text-decoration-underline">
                                    {" "}
                                    Iniciar sesión{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SignUp;
