import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Row } from "reactstrap";
import CountryOptions from "../../Home/SubSection/CountryOptions";
import JobType from "../../Home/SubSection/JobType";

const JobGridHeader = ({ setFilters }) => {
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState({ label: "Colombia", value: 47 });
  const [jobType, setJobType] = useState({ label: "Seleccione una opción", value: null });

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilters({
      companyName,
      location: location.label,
      jobType: jobType.label
    });
  };

  return (
    <React.Fragment>
      <form action="#">
        <Row className="g-2">
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-briefcase-alt"></i>
              <Input
                type="search"
                className="form-control filter-input-box"
                id="exampleFormControlInput1"
                placeholder="Nombre de la Empresa... "
                style={{ marginTop: "-10px" }}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-location-point"></i>
              <CountryOptions value={location} onChange={(selectedOption) => setLocation(selectedOption)} />
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="filler-job-form">
              <i className="uil uil-clipboard-notes"></i>
              <JobType value={jobType} onChange={(selectedOption) => setJobType(selectedOption)} />
            </div>
          </Col>
          <Col lg={3} md={6}>
            <Link to="#" className="btn btn-primary w-100" onClick={handleSubmit}>
              <i className="uil uil-filter"></i> Filtro
            </Link>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  );
};

export default JobGridHeader;
