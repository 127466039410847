import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Modal, ModalBody, Row, ModalHeader } from "reactstrap";
import { API_URL } from "../../../config";
import companyImg from "../../../assets/images/companyImg.jpg";
import Pagination from "../../Jobs/JobList2/Pagination";
import { getJobs_id, getCompany, deletejoboffer, putJobOfferStatus, putJobOfferSuspendedStatus, getJobOfferApplications, getApplications, getEmployees3 } from "../../../services/userservice";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const JobListing = () => {
  const [modal, setModal] = useState(false);
  const [applicationModal, setApplicationModal] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [company, setCompany] = useState();
  const [selectedId, setSelectedId] = useState(null);
  const [img, setImg] = useState("");
  const user = localStorage.getItem('id');
  const userRol = localStorage.getItem("rol");
  const [applications, setApplications] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [selectedJobApplications, setSelectedJobApplications] = useState([]);



  useEffect(() => {
    getCompany(user)
      .then(data => {
        setCompany(data.id);
        setImg(data.photo);
      })
      .catch(error => console.error(error));
  }, [user]);

  useEffect(() => {
    getApplications()
    .then(data => {
      setApplications(data);
    })
    .catch(error => console.error(error));

    if (company) {
      getJobs_id(company)
        .then(data => {setJobs(data); console.log("JOBS",data)})
        .catch(error => console.error(error));
    }
  }, [company]);

  const navigate = useNavigate();

  const eliminador = () => {
    putJobOfferStatus(selectedId, false)
      .then(() => {
        toast.success("La oferta de trabajo ha sido eliminada.");
        getJobs_id(company)
          .then(data => setJobs(data))
          .catch(error => console.error(error));
      })
      .catch(error => {
        toast.error("Error al eliminar la oferta de trabajo.");
        console.error(error);
      });
    navigate('/'); // Cambia la ruta según tus necesidades
  };

  const getApplicationCount = (jobOfferId) => {
    return applications.filter(app => app.job_offer_id === jobOfferId).length;
  };

  const handleClickPostulaciones = (jobVacancyDetails) => {
    getJobOfferApplications(jobVacancyDetails.id)
      .then(data => openApplicationModal(jobVacancyDetails.title, data))
      .catch(error => console.log(error));
  };

  const openApplicationModal = async (jobTitle, employee_list) => {
    setModalTitle(jobTitle);

    const employeeDetailsList = await Promise.all(
      employee_list.map(async (employee) => {
        const employeeDetails = await getEmployees3(employee.employee_id);
        return {
          user_name: employeeDetails.name,
          user_last_name: employeeDetails.lastname,
          user_id: employeeDetails.id
        };
      })
    );

    setSelectedJobApplications(employeeDetailsList);
    setApplicationModal(true);
  };

  const toggleModal = () => {
    setApplicationModal(!applicationModal);
  };

  const editar = (id) => {
    localStorage.setItem('job_id', id);
    navigate('/bookmarkjobpost');
  };

  const openModal = (id) => {
    setModal(!modal);
    setSelectedId(id);
  };

  const suspender = (id) => {
    //Si es true esta suspendida la oferta
    putJobOfferSuspendedStatus(id, true)
      .then(() => {
        toast.success("La oferta de trabajo ha sido suspendida.");
        getJobs_id(company)
          .then(data => setJobs(data))
          .catch(error => console.error(error));
      })
      .catch(error => {
        toast.error("Error al suspender la oferta de trabajo.");
        console.error(error);
      });
  };

  const activar = (id) => {
    //Si es false la oferta se activa
    putJobOfferSuspendedStatus(id, false)
      .then(() => {
        toast.success("La oferta de trabajo ha sido activada.");
        getJobs_id(company)
          .then(data => setJobs(data))
          .catch(error => console.error(error));
      })
      .catch(error => {
        toast.error("Error al activar la oferta de trabajo.");
        console.error(error);
      });
  };

  return (
    <React.Fragment>
      <style>
        {`
          .suspended-job {
            border-left: 5px solid red;
            opacity: 0.6;
          }
          
          .clickable-text {
            color: blue;
            text-decoration: underline;
            cursor: pointer;
          }
            
        `}
      </style>
      <Row>
        <Col lg={12}>
          {jobs
            .filter(jobListingDetails => jobListingDetails.status) // Filtrar las ofertas con status en true
            .map((jobListingDetails, key) => (
              <Card className={`job-box card mt-4 ${jobListingDetails.suspended ? 'suspended-job' : ''}`} key={key}>
                <CardBody className="p-4">
                  <Row>
                    <Col lg={1}>
                      <Link to="/companydetails">
                        <img
                          src={`${API_URL}download2/${img}`}
                          alt=""
                          className="img-fluid rounded-3"
                          onError={(e) => { e.target.src = companyImg; }}
                        />
                      </Link>
                    </Col>

                    <Col lg={9}>
                      <div className="mt-3 mt-lg-0">
                        <h5 className="fs-17 mb-1">
                          <Link to="/jobdetails" onClick={() => localStorage.setItem("jobs", jobListingDetails.id)} className="text-dark">
                            {jobListingDetails.title}
                          </Link>
                        </h5>
                        <ul className="list-inline mb-0">
                          <li className="list-inline-item">
                            <p className="text-muted fs-14 mb-0">
                              {jobListingDetails.modality}
                            </p>
                          </li>
                          <li className="list-inline-item">
                            <p className="text-muted fs-14 mb-0">
                              <i className="mdi mdi-map-marker"></i>{" "}
                              {jobListingDetails.city}
                            </p>
                          </li>
                          <li className="list-inline-item">
                            <p className="text-muted fs-14 mb-0">
                              <i className="uil uil-wallet"></i>{" "}
                              {jobListingDetails.salary}
                            </p>
                          </li>
                        </ul>
                        <div className="mt-2">
                          <span
                            className={
                              jobListingDetails.fullTime === true
                                ? "badge bg-success-subtle text-success fs-13 mt-1 mx-1"
                                : jobListingDetails.partTime === true
                                ? "badge bg-danger-subtle text-danger fs-13 mt-1 mx-1"
                                : jobListingDetails.freeLance === true
                                ? "badge bg-primary-subtle text-primary fs-13 mt-1 mx-1"
                                : jobListingDetails.internship === true
                                ? "badge bg-blue-subtle text-blue fs-13 mt-1"
                                : ""
                            }
                          >
                            {jobListingDetails.timing}
                          </span>
                          <div className="mt-2">
                            <span className="text-muted fs-13">Publicado el: {new Date(jobListingDetails.created_at).toLocaleDateString()}</span>
                          </div>
                          {/* El rol 1 pertenece a el usuario empresa */}
                          {userRol === '1' ? 
                            <div className="mt-2">
                              <span className="text-muted fs-13 clickable-text" onClick={ () => {handleClickPostulaciones(jobListingDetails)}}>
                                Postulaciones: {getApplicationCount(jobListingDetails.id)}
                              </span>
                            </div>
                            : 
                            <div></div>
                          }
                          {(jobListingDetails.badges || []).map(
                            (badgeInner, key) => (
                              <span
                                className={`badge ${badgeInner.badgeclassName} fs-13 mt-1`}
                                key={key}
                              >
                                {badgeInner.badgeName}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col lg={2} className="align-self-center">
                      <div className="d-flex justify-content-between">
                        <Link
                          to="/bookmarkjobpost"
                          onClick={() => editar(jobListingDetails.id)}
                          className="avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18 mx-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Edit"
                        >
                          <i className="uil uil-edit"></i>
                        </Link>
                        <Link
                          onClick={() => openModal(jobListingDetails.id)}
                          to="#"
                          className="avatar-sm bg-danger-subtle text-danger d-inline-block text-center rounded-circle fs-18 mx-1"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                        >
                          <i className="uil uil-trash-alt"></i>
                        </Link>
                        {jobListingDetails.suspended ? (
                          <Link
                            onClick={() => activar(jobListingDetails.id)}
                            className="avatar-sm bg-success-subtle text-success d-inline-block text-center rounded-circle fs-18 mx-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Activate"
                          >
                            <i className="uil uil-play"></i>
                          </Link>
                        ) : (
                          <Link
                            onClick={() => suspender(jobListingDetails.id)}
                            className="avatar-sm bg-warning-subtle text-warning d-inline-block text-center rounded-circle fs-18 mx-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Suspend"
                          >
                            <i className="uil uil-pause"></i>
                          </Link>
                        )}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ))}
        </Col>
        <Pagination />
      </Row>

      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <Modal isOpen={modal} toggle={openModal} centered tabIndex="-1">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Eliminar Empleo
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={openModal}
              ></button>
            </div>
            <ModalBody>
              <div>
                <h6 className="text-danger">
                  <i className="uil uil-exclamation-triangle"></i> Advertencia:
                  ¿Estás seguro de que quieres eliminar la publicación de trabajo?
                </h6>
                <p className="text-muted">
                  {" "}
                  Su publicación de empleo se eliminará de forma permanente y no podrá
                  poder verlos de nuevo, incluso una vez que se comparten con los candidatos.
                </p>
              </div>
            </ModalBody>
            <div className="modal-footer">
              <button
                type="button"
                onClick={openModal}
                className="btn btn-primary btn-sm"
              >
                Cancelar
              </button>
              <button type="button" onClick={() => { 
                eliminador(); 
                openModal(); 
              }} className="btn btn-danger btn-sm">
                Si, Eliminar
              </button>
            </div>
          </Modal>
        </div>
      </div>

      {/* Modal para mostrar los candidatos que han aplicado  */}
      <Modal isOpen={applicationModal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Postulaciones para {modalTitle}</ModalHeader>
        <ModalBody>
          {selectedJobApplications.length > 0 ? (
            <ul>
              {selectedJobApplications.map((application, index) => (
                <Link
                  to="/candidatedetails"
                  onClick={() => localStorage.setItem("employee", application.user_id)}
                  key={index}
                >
                  <li>{application.user_name} {application.user_last_name}</li>
                </Link>
              ))}
            </ul>
          ) : (
            <p>No hay postulaciones para este empleo.</p>
          )}
        </ModalBody>
      </Modal>

    </React.Fragment>
  );
};

export default JobListing;
