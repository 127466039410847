import React, { useState, useEffect } from "react";
import es from "i18n-iso-countries/langs/es.json";
import countries from "i18n-iso-countries";
import {
  Col,
  Row,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  Card,
  Input,
  Form,
  NavItem,
  CardBody,
  Label,
} from "reactstrap";
import { nationalityList, sexoList, identificationOptions, studyLevelList, salaryRange, languajeList } from "./FieldList";
import { departmentCityOptions } from "../../Home/SubSection/DepartmentCityOptions";
import {
  deleteExperience, postExperience, getExperience, getExperiences, getEmployees,
  deleteEducation, putEducation, getEducations, getEducation, postEmployees,
  postDocumento, postEducation, uploadFileImg, downloadFile, uploadFile,
  getDocument, deleteDocument, deleteDocuments, getCompany, putExperience, putUserPass,
  getUsers
} from "../../../services/userservice";
import classnames from "classnames";
import path from 'path-browserify';
import companyDefaultImage from "../../../assets/images/companyImg.jpg"
import defaultProfileImage from "../../../assets/images/user/img-02.jpg";
import { API_URL } from "../../../config";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

countries.registerLocale(es);
const paises = Object.entries(countries.getNames("es")).map(
  ([codigo, nombre]) => ({
    codigo,
    nombre,
  })
);

const RightSideContent = () => {

  // Estado para las opciones de ciudades basadas en el departamento seleccionado
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  const ids = localStorage.getItem("id");
  const [perfil, setPerfil] = useState("");
  const [email, setEmail] = useState("");
  const [titulo, setTitulo] = useState("");
  const [institucion, setInstitucion] = useState("");
  const [datestart, setDatestart] = useState("");
  const [dateend, setIDateend] = useState("");
  const [documentos, setDocumentos] = useState([]);
  const [mostrarDiv, setMostrarDiv] = useState(true);
  const [mostrarDiv2, setMostrarDiv2] = useState(true);
  const [mostrarDiv3, setMostrarDiv3] = useState(true);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  const [empresa, setEmpresa] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [fechaini, setFechaini] = useState("");
  const [fechater, setFechater] = useState("");
  const [post, setPost] = useState("");
  const [editar, setEditar] = useState(false);
  const [editar2, setEditar2] = useState(false);
  const [idedu, setIdedu] = useState("");
  const [ideexp, setIdeexp] = useState("");
  var rol = parseInt(localStorage.getItem("rol"));

  let downloadImageURL = `${API_URL}download2/${perfil.photo}`;

  useEffect(() => {
    if (rol === 1 || rol === "1") {
      setMostrarDiv(false);
      setMostrarDiv2(false);
      setMostrarDiv3(false);
      getCompany(ids)
        .then(data => setPerfil(data))
        .catch(error => console.error(error));
    } else {
      getEmployees(ids)
        .then(data => setPerfil(data))
        .catch(error => console.error(error));
    }
  }, [ids, rol]);

  // Actualizar las opciones de ciudades cuando cambia el departamento seleccionado
  useEffect(() => {
      const department = departmentCityOptions.find(dept => dept.departamento === selectedDepartment);
      if (department) {
        setCityOptions(department.ciudades);
      } else {
        setCityOptions([]);
      }
    }, [selectedDepartment]);

  useEffect(() => {
    getEducations(ids)
      .then(data => setEducation(data))
      .catch(error => console.error(error));
  }, [ids]);

  useEffect(() => {
    getExperiences(ids)
      .then(data => setExperience(data))
      .catch(error => console.error(error));
  }, [ids]);

  const fetchDocuments = (ids, setDocumentos) => {
    getDocument(ids)
      .then(data => setDocumentos(data))
      .catch(error => console.error(error));
  };

  const getEducacion = (id) => {
    getEducation(id)
      .then(data => {
        setTitulo(data.title);
        setInstitucion(data.institution);
        setDatestart(data.start_date);
        setIDateend(data.end_date);
        setIdedu(data.id);
        setCertificate(data.certificate);
        setEditar(true);
      })
      .catch(error => console.error(error));
  };

  const deleteEducacion = async (id) => {
    await deleteEducation(id)
      .catch(error => console.error(error));
    getEducations(ids)
      .then(data => setEducation(data))
      .catch(error => console.error(error));
  };

  const deleteExperiencia = async (id) => {
    await deleteExperience(id)
      .catch(error => console.error(error));
    getExperiences(ids)
      .then(data => setExperience(data))
      .catch(error => console.error(error));
  };

  const getExperiencia = (id) => {
    getExperience(id)
      .then(data => {
        setEmpresa(data.company);
        setPost(data.post);
        setDescripcion(data.description);
        setFechaini(data.start_date);
        setFechater(data.end_date);
        setJobCertificate(data.certificate)
        setOwnVehicle(data.own_vehicle)
        setInformalJob(data.informal_job)
        setIdeexp(data.id);
        setEditar2(true);
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    fetchDocuments(ids, setDocumentos);
  }, [ids]);

  const [activeTab, setActiveTab] = useState("1");
  const [salario, setSalario] = useState("Seleccione una opción");
  const [name, setName] = useState(perfil.name);
  const [lastname, setLastname] = useState(perfil.lastname);
  const [phone, setPhone] = useState(perfil.phone);
  const [description, setDescription] = useState(perfil.description);
  const [country, setCountry] = useState("CO");
  const [identificationNumber, setIdentificationNumber] = useState("");
  const [typeIdentification, setTypeIdentification] = useState("Seleccione un tipo de identificación");
  const [sexo, setSexo] = useState("Seleccione una opción");
  const [nationality, setNationality] = useState("Seleccione una nacionalidad");
  const [studyLevel, setStudyLevel] = useState("Seleccione una opción");
  const [languaje, setLanguaje] = useState("Seleccione un idioma")
  const [certificate, setCertificate] = useState(false);
  const [birthDate, setBirthDate] = useState('');
  const [city, setCity] = useState();
  const [firstJob, setFirstJob] = useState(false);
  const [jobCertificate, setJobCertificate] = useState(false);
  const [ownVehicle, setOwnVehicle] = useState(false);
  const [informalJob, setInformalJob] = useState(false);
  const [actualPass, setActualPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [neighborhood, setNeighborhood] = useState('');
  const [adress, setAdress] = useState(perfil.adress);
  const [id, setId] = useState(perfil.id);
  const [photo, setPhoto] = useState(null);
  const [src, setSrc] = useState("");
  const [documento, setDocumento] = useState(null);
  const [errors, setErrors] = useState({});

  const [companyNit, setCompanyNit] = useState();
  const [checkDigit, setCheckDigit] = useState();
  const [companyContactName, setCompanyContactName] = useState();
  const [companyContactPosition, setCompanyContactPosition] = useState();
  const [companyContactNumber, setCompanyContactNumber] = useState();


  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(100), (val, index) => currentYear - index);

  const handleFileChange = (e) => {
    setDocumento(e.target.files[0]);
  };

  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setPhoto(file);
    if (file) {
      setSrc(URL.createObjectURL(file));
    }
  };

  const handleFileDownload = async (name) => {
    try {
      const fileData = await downloadFile(name);
      const url = window.URL.createObjectURL(new Blob([fileData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
    }
  };

  const insertaEducation = async () => {
    if (editar === true) {
      await putEducation(titulo, institucion, datestart, dateend, ids, idedu,certificate);
      setTitulo("");
      setInstitucion("");
      setDatestart("");
      setIDateend("");
      setIdedu("");
      setCertificate(false);
      setEditar(false);
      getEducations(ids)
        .then(data => setEducation(data))
        .catch(error => console.error(error));
    } else {
      await postEducation(titulo, institucion, datestart, dateend, ids, certificate);
      setTitulo("");
      setInstitucion("");
      setDatestart("");
      setIDateend("");
      setCertificate(false);
      getEducations(ids)
        .then(data => setEducation(data))
        .catch(error => console.error(error));
    }
  };

  const insertaExperience = async () => {
    if (editar2 === true) {
      await putExperience(empresa, post, descripcion, fechaini, fechater, ids, ideexp,jobCertificate, informalJob, ownVehicle);
      setEmpresa("");
      setPost("");
      setDescripcion("");
      setFechaini("");
      setFechater("");
      setIdeexp("");
      setEditar2(false);
      setJobCertificate(false);
      setOwnVehicle(false);
      setInformalJob(false);
      getExperiences(ids)
        .then(data => setExperience(data))
        .catch(error => console.error(error));
    } else {
      await postExperience(empresa, post, descripcion, fechaini, fechater, ids, jobCertificate, informalJob, ownVehicle);
      setEmpresa("");
      setPost("");
      setDescripcion("");
      setFechaini("");
      setFechater("");
      setJobCertificate(false);
      setOwnVehicle(false);
      setInformalJob(false);
      getExperiences(ids)
        .then(data => setExperience(data))
        .catch(error => console.error(error));
    }
  };

  const eliminarDocumento = async (name, id) => {
    try {
      await deleteDocuments(id);
      await deleteDocument(name);
      fetchDocuments(ids, setDocumentos);
    } catch (error) {
      console.error('Error al eliminar el archivo:', error);
    }
  };

  useEffect(() => {
    setName(perfil.name || "");
    setLastname(perfil.lastname || "");
    setPhone(perfil.phone || "");
    setDescription(perfil.description || "");
    setCountry(perfil.country || "CO");
    setCity(perfil.city || "");
    setSelectedDepartment(perfil.department || "");
    setNeighborhood(perfil.neighborhood || "");
    
    setCompanyNit(perfil.nit || "");
    setCheckDigit(perfil.check_digit || "");
    setCompanyContactName(perfil.contact_name);
    setCompanyContactPosition(perfil.contact_position);
    setCompanyContactNumber(perfil.contact_number);

    setAdress(perfil.adress || "");
    setSalario(perfil.salary || "");
    setLanguaje(perfil.languaje || "")
    setEmail(localStorage.getItem("email"));
    setId(perfil.id || "");
    setPhoto(perfil.photo || "");
    setIdentificationNumber(perfil.identificationNumber || "");
    setTypeIdentification(perfil.typeIdentification || "Seleccione un tipo de identificación");
    setSexo(perfil.sexo || "Seleccione una opción");
    setNationality(perfil.nationality || "Seleccione una nacionalidad");
    setStudyLevel(perfil.studyLevel || "Seleccione una opción");
    setBirthDate(perfil.birthDate || "");
    setFirstJob(perfil.first_job || false);
    if (perfil.photo) {
      const timestamp = new Date().getTime();
      setSrc(`${downloadImageURL}?t=${timestamp}`);
    } else {
      if(rol === 1 || rol === '1'){
        setSrc(companyDefaultImage);
      }else{
        setSrc(defaultProfileImage);
      }
    }
  }, [perfil]);

  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const updateUserPass = async () => {
    try {
      let userData = await getUsers(ids);
      let userPass = userData.password ? userData.password : false;

      if(userPass === false) {
        toast.error("No se pudo obtener la información del usuario")
        return false;
      }

      if(newPass.length === 0 ){
        return
      }

      const passwordValidation = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

      if (newPass.length !== 0 && !passwordValidation.test(newPass)) {
        toast.error("La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un número");
        return false;
      }
      
      if (actualPass === userPass && newPass === confirmPass) {
        let response = await putUserPass(ids, newPass);
        
        if (response.status === 200) {
          toast.success("Contraseña actualizada correctamente");
          return true;
        } else {
          toast.error("No se pudo actualizar la contraseña");
          return false;
        }
      } else {
        toast.error("Las contraseñas no coinciden o la contraseña actual es incorrecta");
        return false;
      }
    } catch (error) {
      toast.error("Error al actualizar la contraseña");
      console.error("Error:", error);
      return false;
    }
  }
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if(rol === 2){
      if (identificationNumber.trim() === "") newErrors.identificationNumber = "El número de identificación es obligatorio."; 
      if(identificationNumber.trim().length < 4) newErrors.identificationNumber = "El número de identificación debe de contener mas de 4 digitos";
      if (typeIdentification === "Seleccione un tipo de identificación") newErrors.typeIdentification = "Seleccione un tipo de identificación válido.";
      if (sexo === "Seleccione una opción") newErrors.sexo = "Seleccione una opción válida.";
      if (nationality === "Seleccione una nacionalidad") newErrors.nationality = "Seleccione una nacionalidad válida.";
      if (studyLevel === "Seleccione una opción") newErrors.studyLevel = "Seleccione un nivel de estudio válido.";
      if (birthDate.trim() === "") newErrors.birthDate = "La fecha de nacimiento es obligatoria.";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Por favor, complete todos los campos obligatorios correctamente.");
      return;
    }

    setErrors({});

    var imagen = photo;
    var nombreImg = "";
    if (photo) {
      try {
        const extension = path.extname(photo.name);
        nombreImg = ids + extension + ""
        imagen = nombreImg;
        console.log(photo)
        const filename = await uploadFileImg(photo);
        imagen = nombreImg
        console.log('Imagen subido:', filename);
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    }

    let employeData = {
      name:name,
      lastname:lastname,
      phone:phone,
      description:description,
      salary:salario,
      country:country,
      city:city,
      departmen:selectedDepartment,
      adress:adress,
      id:id,
      photo:imagen,
      identificationNumber:identificationNumber,
      typeIdentification:typeIdentification,
      sexo:sexo,
      nationality:nationality,
      studyLevel:studyLevel,
      birthDate:birthDate,
      neighborhood:neighborhood,
      languaje:languaje,
      first_job:firstJob,
      //Company info
      nit: companyNit ,
      check_digit: checkDigit,
      contact_name: companyContactName,
      contact_position: companyContactPosition,
      contact_number: companyContactNumber

    }
    await postEmployees(employeData);
    let updated = await updateUserPass();
    
    if (documento) {
      await postDocumento(documento.name, documento.type);
      try {
        const filename = await uploadFile(documento);
        setDocumento(null);
        console.log('Archivo subido:', filename);
      } catch (error) {
        console.error('Error al subir el archivo:', error);
      }
    }
    fetchDocuments(ids, setDocumentos);
    if (updated === false){
      return
    } 
    window.location.replace('/myprofile');
  };

  return (
    <React.Fragment>
      <Col lg={8}>
        <Card className="profile-content-page mt-4 mt-lg-0">
          <Nav className="profile-content-nav nav-pills border-bottom mb-4" id="pills-tab" role="tablist">
            <NavItem role="presentation">
              <NavLink to="#" className={classnames({ active: activeTab === "1" })} onClick={() => { tabChange("1"); }} type="button">
                Descripción General
              </NavLink>
            </NavItem>
            <NavItem role="presentation">
              <NavLink to="#" className={classnames({ active: activeTab === "2" })} onClick={() => { tabChange("2"); }} type="button">
                Editar Perfil
              </NavLink>
            </NavItem>
          </Nav>

          <CardBody className="p-4">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div>
                  <h5 className="fs-18 fw-bold">Quien Soy</h5>
                  <p className="text-muted mt-4">{description}</p>
                </div>
                {mostrarDiv3 && (
                  <div className="candidate-education-details mt-4">
                    <h6 className="fs-18 fw-bold mb-0">Educacion</h6>
                    {education.map((jobListingDetails, key) => (
                      <div className="candidate-education-content mt-4 d-flex" key={key}>
                        <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                          {jobListingDetails.start_date.substring(0, 4)}
                        </div>
                        <div className="ms-4">
                          <h6 className="fs-16 mb-1">{jobListingDetails.title}</h6>
                          <p className="mb-2 text-muted">{jobListingDetails.institution} -  ({jobListingDetails.start_date} - {jobListingDetails.end_date})</p>
                          <p className="text-muted"></p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {
                  rol === 1 ?
                  <div className="candidate-education-details mt-4">
                    <h6 className="fs-18 fw-bold mb-0">Experiencia</h6>
                    {experience.map((jobListingDetails, key) => (
                      <div className="candidate-education-content mt-4 d-flex" key={key}>
                        <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                          {jobListingDetails.start_date.substring(0, 4)}
                        </div>
                        <div className="ms-4">
                          <h6 className="fs-16 mb-1">{jobListingDetails.company}</h6>
                          <p className="mb-2 text-muted">{jobListingDetails.post} - ({jobListingDetails.start_date} - {jobListingDetails.end_date})</p>
                          <p className="text-muted">{jobListingDetails.description}</p>
                        </div>
                      </div>
                    ))}
                  </div>: <></>
                }
              </TabPane>
              <TabPane tabId="2">
                <Form action="/" className="auth-form" onSubmit={handleSubmit}>
                  <div>
                    <h5 className="fs-17 fw-semibold mb-3 mb-0">Mi Cuenta</h5>
                    <div className="text-center">
                      <div className="mb-4 profile-user">
                        <img
                          src={src}
                          className="rounded-circle img-thumbnail profile-img"
                          id="profile-img"
                          alt=""
                          onError={(e) => { rol === 1 || '1' ? e.target.src = companyDefaultImage: e.target.src = defaultProfileImage; }}
                        />
                        <div className="p-0 rounded-circle profile-photo-edit">
                          <Input
                            id="profile-img-file-input"
                            type="file"
                            className="profile-img-file-input"
                            onChange={handleFileChange2}
                          />
                          <Label
                            htmlFor="profile-img-file-input"
                            className="profile-photo-edit avatar-xs"
                          >
                            <i className="uil uil-edit"></i>
                          </Label>
                        </div>
                      </div>
                    </div>
                    <Row>
                      {
                        rol === 2 ?
                        <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Tipo de Identificación
                          </label>
                          <select
                            className={`form-select ${errors.typeIdentification ? "is-invalid" : ""}`}
                            data-trigger
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={typeIdentification}
                            required
                            onChange={(e) => setTypeIdentification(e.target.value)}
                          >
                            {identificationOptions.map((identification) => (
                              <option key={identification.identification_value} value={identification.identification_value}>
                                {identification.identification_value}
                              </option>
                            ))}
                          </select>
                          {errors.typeIdentification && <div className="invalid-feedback">{errors.typeIdentification}</div>}
                        </div>
                      </Col> : <></>
                      }
                      
                      {
                      rol === 2 ?
                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Número de Identificación
                          </label>
                          <Input
                            type="text"
                            className={`form-control ${errors.identificationNumber ? "is-invalid" : ""}`}
                            id="identificationNumber"
                            required
                            value={identificationNumber}
                            onChange={(e) => setIdentificationNumber(e.target.value)}
                          />
                          {errors.identificationNumber && <div className="invalid-feedback">{errors.identificationNumber}</div>}
                        </div>
                      </Col>
                      :
                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="nit" className="form-label">
                            NIT
                          </label>
                          <Input
                            type="text"
                            className={`form-control ${errors.companyNit ? "is-invalid" : ""}`}
                            id="companyNit"
                            required
                            value={companyNit}
                            onChange={(e) => setCompanyNit(e.target.value)}
                          />
                          {errors.companyNit && <div className="invalid-feedback">{errors.companyNit}</div>}
                        </div>
                      </Col>
                      }
                      {
                        rol === 1 ? 
                        <>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="checkDigit" className="form-label">
                                Digito de verificación
                              </label>
                              <Input
                                type="text"
                                className={`form-control ${errors.checkDigit ? "is-invalid" : ""}`}
                                id="checkDigit"
                                required
                                value={checkDigit}
                                onChange={(e) => setCheckDigit(e.target.value)}
                              />
                              {errors.checkDigit && <div className="invalid-feedback">{errors.checkDigit}</div>}
                            </div>
                            
                          </Col>
                        </>
                        :
                        <></>
                        
                      }

                      {
                        rol === 1 ? 
                        <>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="checkDigit" className="form-label">
                              Nombre de persona contacto
                              </label>
                              <Input
                                type="text"
                                className={`form-control ${errors.companyContactName ? "is-invalid" : ""}`}
                                id="companyContactName"
                                required
                                value={companyContactName}
                                onChange={(e) => setCompanyContactName(e.target.value)}
                              />
                              {errors.companyContactName && <div className="invalid-feedback">{errors.companyContactName}</div>}
                            </div>
                            
                          </Col>
                        </>
                        :
                        <></>
                        
                      }

                      {
                        rol === 1 ? 
                        <>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="companyContactNumber" className="form-label">
                              Celular de persona contacto
                              </label>
                              <Input
                                type="text"
                                className={`form-control ${errors.companyContactNumber ? "is-invalid" : ""}`}
                                id="companyContactNumber"
                                required
                                value={companyContactNumber}
                                onChange={(e) => setCompanyContactNumber(e.target.value)}
                              />
                              {errors.companyContactNumber && <div className="invalid-feedback">{errors.companyContactNumber}</div>}
                            </div>
                            
                          </Col>
                        </>
                        :
                        <></>
                        
                      }

                      {
                        rol === 1 ? 
                        <>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="companyContactPosition" className="form-label">
                              Cargo de persona contacto
                              </label>
                              <Input
                                type="text"
                                className={`form-control ${errors.companyContactPosition ? "is-invalid" : ""}`}
                                id="companyContactPosition"
                                required
                                value={companyContactPosition}
                                onChange={(e) => setCompanyContactPosition(e.target.value)}
                              />
                              {errors.companyContactPosition && <div className="invalid-feedback">{errors.companyContactPosition}</div>}
                            </div>
                            
                          </Col>
                        </>
                        :
                        <></>
                        
                      }



                      {
                        rol === 2 ?
                        <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Sexo al nacer
                          </label>
                          <select
                            className={`form-select ${errors.sexo ? "is-invalid" : ""}`}
                            data-trigger
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={sexo}
                            required
                            onChange={(e) => setSexo(e.target.value)}
                          >
                            {sexoList.map((sexo) => (
                              <option key={sexo.tipo} value={sexo.tipo}>
                                {sexo.tipo}
                              </option>
                            ))}
                          </select>
                          {errors.sexo && <div className="invalid-feedback">{errors.sexo}</div>}
                        </div>
                      </Col> 
                      : <></>
                      }

                      {
                      rol === 2 ? 
                      <>
                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="birthDate" className="form-label">
                            Fecha de Nacimiento
                          </label>
                          <Input
                            required
                            type="date"
                            className={`form-control ${errors.birthDate ? "is-invalid" : ""}`}
                            id="birthDate"
                            value={birthDate}
                            onChange={(e) => setBirthDate(e.target.value)}
                          />
                          {errors.birthDate && <div className="invalid-feedback">{errors.birthDate}</div>}
                        </div>
                      </Col>



                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Nacionalidad
                          </label>
                          <select
                            required
                            className={`form-select ${errors.nationality ? "is-invalid" : ""}`}
                            data-trigger
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={nationality}
                            onChange={(e) => setNationality(e.target.value)}
                          >
                            {nationalityList.map((nacionalidad) => (
                              <option key={nacionalidad.tipo} value={nacionalidad.tipo}>
                                {nacionalidad.tipo}
                              </option>
                            ))}
                          </select>
                          {errors.nationality && <div className="invalid-feedback">{errors.nationality}</div>}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <label htmlFor="firstName" className="form-label">
                            Nivel de estudio culminado
                          </label>
                          <select
                            className={`form-select ${errors.studyLevel ? "is-invalid" : ""}`}
                            data-trigger
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={studyLevel}
                            required
                            onChange={(e) => setStudyLevel(e.target.value)}
                          >
                            {studyLevelList.map((academy) => (
                              <option key={academy.study} value={academy.study}>
                                {academy.study}
                              </option>
                            ))}
                          </select>
                          {errors.studyLevel && <div className="invalid-feedback">{errors.studyLevel}</div>}
                        </div>
                      </Col>
                      </>
                      : <></>
                    }
                      {rol === 1 || rol === "1" ? (
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              Razon Social
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </Col>
                      ) : (
                        <Col lg={6}>
                          <div className="mb-3">
                            <label htmlFor="firstName" className="form-label">
                              Nombres
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                        </Col>
                      )}
                      {mostrarDiv2 && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="lastName" className="form-label">
                              Apellidos
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                            />
                          </div>
                        </Col>
                      )}
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Celular
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Dirección
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="addres"
                            value={adress}
                            onChange={(e) => setAdress(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <label
                            htmlFor="choices-single-categories"
                            className="form-label"
                          >
                            Pais
                          </label>
                          <select
                            className="form-select"
                            data-trigger
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={country}
                            onChange={(e) => setCountry(e.target.value)}
                          >
                            {paises.map((pais) => (
                              <option key={pais.codigo} value={pais.nombre}>
                                {pais.nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="departmen" className="form-label">
                            Departamento
                          </Label>
                          <select
                            className="form-select"
                            data-trigger
                            required
                            name="choices-single-location"
                            id="choices-single-location"
                            aria-label="Default select example"
                            value={selectedDepartment}
                            onChange={(e) => setSelectedDepartment(e.target.value)}
                          >
                            {departmentCityOptions.map((departamento) => (
                              <option key={departamento.id} value={departamento.departamento}>
                                {departamento.departamento}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="city" className="form-label">
                            Ciudad
                          </Label>
                          <select
                            required
                            className="form-select"
                            id="city"
                            value={city}
                            onChange={(e) => setCity(e.target.value)}
                          >
                            {cityOptions.map((ciudad, index) => (
                              <option key={index} value={ciudad}>
                                {ciudad}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      {
                        rol === 2 ? 
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Barrio
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="neighborhood"
                            value={neighborhood}
                            onChange={(e) => setNeighborhood(e.target.value)}
                          />
                        </div>
                      </Col>
                        : <></>
                      }

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="email" className="form-label">
                            Email
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3">Perfil</h5>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Presentacion
                          </Label>
                          <textarea
                            className="form-control"
                            rows="5"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </div>
                      </Col>
                      {
                        rol === 2 ?
                        <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="languages" className="form-label">
                            Idiomas
                          </Label>
                          <select
                            required
                            className="form-select"
                            id="languaje"
                            value={languaje}
                            onChange={(e) => setLanguaje(e.target.value)}
                          >
                            {languajeList.map((lenguaje) => (
                              <option key={lenguaje.valor} value={lenguaje.valor}>
                                {lenguaje.valor}
                              </option>
                            ))}
                          </select>
                        </div>
                      </Col>
                      :
                      <></>
                      }
                      {mostrarDiv && (
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label htmlFor="salario" className="form-label">
                              Aspiracion Salarial
                            </Label>
                            <select
                            required
                            className="form-select"
                            id="salario"
                            value={salario}
                            onChange={(e) => setSalario(e.target.value)}
                          >
                            {salaryRange.map((salary) => (
                              <option key={salary.valor} value={salary.valor}>
                                {salary.valor}
                              </option>
                            ))}
                          </select>
                          </div>
                        </Col>
                      )}
                      <Col lg={12}>
                        <div className="mb-3">
                          {rol === 1 || rol === "1" ? (
                            <Label htmlFor="attachmentscv" className="form-label">
                              Adjuntos Camara de Comercio
                            </Label>
                          ) : (
                            <Label htmlFor="attachmentscv" className="form-label">
                              Adjuntos CV
                            </Label>
                          )}
                          <Input
                            className="form-control"
                            type="file"
                            id="attachmentscv"
                            onChange={handleFileChange}
                          />
                        </div>
                      </Col>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="attachmentscv" className="form-label">
                            Lista de Archivos
                          </Label>
                          <ul>
                            {documentos.map((file, index) => (
                              <li key={index} className="d-flex justify-content-between align-items-center mb-3">
                                <span>{file.name}</span>
                                <div>
                                  <a
                                    href={file.type}
                                    onClick={() => handleFileDownload(file.name)}
                                    download
                                    className="btn btn-primary btn-sm me-2"
                                  >
                                    Descargar
                                  </a>
                                  <a
                                    href={file.type}
                                    onClick={() => eliminarDocumento(file.name, file.id)}
                                    download
                                    className="btn btn-danger btn-sm"
                                  >
                                    Eliminar
                                  </a>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  {mostrarDiv2 && (
                    <div className="mt-4">
                      <h5 className="fs-17 fw-semibold mb-3">Formación Académica</h5>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-3 mt-3">
                            <Label className="form-label">
                              Lista de Estudios
                            </Label>
                            <ul>
                              {education.map((file, index) => (
                                <li key={index} className="d-flex justify-content-between align-items-center mb-3">
                                  <span>{file.title}</span>
                                  <div >
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm me-2"
                                      onClick={() => getEducacion(file.id)}
                                    >
                                      Editar
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteEducacion(file.id)}
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Titulo Obtenido
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="titulo"
                              value={titulo}
                              onChange={(e) => setTitulo(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Nombre de la Institución
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="institucion"
                              value={institucion}
                              onChange={(e) => setInstitucion(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <label htmlFor="start_year" className="form-label">
                              Año de Inicio
                            </label>
                            <select
                              className="form-control"
                              id="start_year"
                              value={datestart}
                              onChange={(e) => setDatestart(e.target.value)}
                            >
                              <option value="">Seleccione un año</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <label htmlFor="lastdate" className="form-label">
                              Año Fin
                            </label>
                            <select
                              className="form-control"
                              id="start_year"
                              value={dateend}
                              onChange={(e) => setIDateend(e.target.value)}
                            >
                              <option value="">Seleccione un año</option>
                              {years.map((year) => (
                                <option key={year} value={year}>
                                  {year}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="d-flex align-items-center mb-4" style={{ height: "100%" }}>
                            <label
                              htmlFor="certificate"
                              className="form-label me-2"
                              style={{ lineHeight: "22px" }} // Asegura que el label esté alineado con el checkbox
                            >
                              Cuento con certificado
                            </label>
                            <Input
                              type="checkbox"
                              style={{
                                marginBottom: "10px",
                                border: "2px solid #4A4A4A",
                                width: "22px",
                                height: "22px"
                              }}
                              className="form-check-input"
                              checked={certificate}
                              onChange={(e) => setCertificate(e.target.checked)}
                              id="certificate"
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                        </Col>

                        <Col lg={12} style={{textAlign:"center"}}>
                          <button
                            type="button"
                            onClick={() => insertaEducation()}
                            className="btn btn-primary"
                            style={{ width: "132px", height: "44px" }}
                          >
                            Añadir
                          </button>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {mostrarDiv2 && (
                    <div className="mt-4">
                      <h5 className="fs-17 fw-semibold mb-3">Experiencia Laboral</h5>
                      <Row>
                      <Col lg={4}>
                        <div className="d-flex align-items-center mb-4" style={{ height: "100%" }}>
                          <label
                            htmlFor="firstJob"
                            className="form-label me-2"
                            style={{ lineHeight: "22px", color: "#4A4A4A" }} // Color negro suave y alineación
                          >
                            Mi primer empleo
                          </label>
                          <Input
                            type="checkbox"
                            style={{
                              marginBottom: "10px",
                              border: "2px solid #4A4A4A",
                              width: "22px",
                              height: "22px"
                            }}
                            className="form-check-input"
                            checked={firstJob}
                            onChange={(e) => setFirstJob(e.target.checked)}
                            id="firstJob"
                          />
                        </div>
                      </Col>
                      {
                      !firstJob ?
                      <>
                        <Col lg={12}>
                          <div className="mb-3 mt-3">
                            <Label className="form-label">
                              Lista de Experiencias
                            </Label>
                            <ul>
                              {experience.map((file, index) => (
                                <li key={index} className="d-flex justify-content-between align-items-center mb-3">
                                  <span>{file.post}</span>
                                  <div>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm me-2"
                                      onClick={() => getExperiencia(file.id)}
                                    >
                                      Editar
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                      onClick={() => deleteExperiencia(file.id)}
                                    >
                                      Eliminar
                                    </button>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Nombre de la Empresa
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="Empresa"
                              value={empresa}
                              onChange={(e) => setEmpresa(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Ultimo Cargo que desempeñó
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="cargo"
                              value={post}
                              onChange={(e) => setPost(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-3">
                            <Label className="form-label">
                              Funciones (Mencione las 2 responsabilidades principales de este cargo)
                            </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="descripcion"
                              value={descripcion}
                              onChange={(e) => setDescripcion(e.target.value)}
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <label htmlFor="lastdate" className="form-label">
                              Fecha Inicio
                            </label>
                            <Input type="date" className="form-control" value={fechaini} onChange={(e) => setFechaini(e.target.value)} id="start_date" />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-4">
                            <label htmlFor="lastdate" className="form-label">
                              Fecha Fin
                            </label>
                            <Input type="date" className="form-control" value={fechater} onChange={(e) => setFechater(e.target.value)} id="end_date" />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="d-flex align-items-center mb-4" style={{ height: "100%" }}>
                            <label
                              htmlFor="jobCertificate"
                              className="form-label me-2"
                              style={{ lineHeight: "22px", color: "#4A4A4A" }} // Color negro suave y alineación
                            >
                              Cuento con Certificado
                            </label>
                            <Input
                              type="checkbox"
                              style={{
                                marginBottom: "10px",
                                border: "2px solid #4A4A4A",
                                width: "22px",
                                height: "22px"
                              }}
                              className="form-check-input"
                              checked={jobCertificate}
                              onChange={(e) => setJobCertificate(e.target.checked)}
                              id="jobCertificate"
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                        </Col>
                        
                        <Col lg={6}>
                          <div className="d-flex align-items-center mb-4" style={{ height: "100%" }}>
                            <label
                              htmlFor="informalJob"
                              className="form-label me-2"
                              style={{ lineHeight: "22px", color: "#4A4A4A" }} // Color negro suave y alineación
                            >
                              Trabajo Informal
                            </label>
                            <Input
                              type="checkbox"
                              style={{
                                marginLeft: "49px",
                                marginBottom: "10px",
                                border: "2px solid #4A4A4A",
                                width: "22px",
                                height: "22px"
                              }}
                              className="form-check-input"
                              checked={informalJob}
                              onChange={(e) => setInformalJob(e.target.checked)}
                              id="informalJob"
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                        </Col>

                        <Col lg={6}>
                          <div className="d-flex align-items-center mb-4" style={{ height: "100%" }}>
                            <label
                              htmlFor="ownVehicle"
                              className="form-label me-2"
                              style={{ lineHeight: "22px", color: "#4A4A4A" }} // Color negro suave y alineación
                            >
                              ¿Cuenta con medio de transporte propio?
                            </label>
                            <Input
                              type="checkbox"
                              style={{
                                marginBottom: "10px",
                                border: "2px solid #4A4A4A",
                                width: "22px",
                                height: "22px"
                              }}
                              className="form-check-input"
                              checked={ownVehicle}
                              onChange={(e) => setOwnVehicle(e.target.checked)}
                              id="ownVehicle"
                            />
                          </div>
                        </Col>

                        <Col lg={6}>
                        </Col>

                        <Col lg={12} style={{textAlign:"center"}}>
                          <button
                            type="button"
                            onClick={() => insertaExperience()}
                            className="btn btn-primary"
                            style={{ width: "132px", height: "44px" }}
                          >
                            Añadir
                          </button>
                        </Col>
                      </>
                        : 
                        <></>
                        }

                      </Row>
                      
                    </div>
                  )}
                  <div className="mt-4">
                    <h5 className="fs-17 fw-semibold mb-3 mb-3">
                      Cambiar Contraseña
                    </h5>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label
                            htmlFor="current-password-input"
                            className="form-label"
                          >
                            Contraseña actual
                          </Label>
                          <Input
                            value={actualPass}
                            onChange={(e) => setActualPass(e.target.value)}
                            type="password"
                            className="form-control"
                            placeholder="Ingresa tu contraseña actual"
                            id="current-password-input"
                            
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="new-password-input"
                            className="form-label"
                          >
                            Nueva Contraseña
                          </Label>
                          <Input
                            type="password"
                            value={newPass}
                            onChange={(e) => setNewPass(e.target.value)}
                            className="form-control"
                            placeholder="Ingresa tu nueva contraseña"
                            id="new-password-input"
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="confirm-password-input"
                            className="form-label"
                          >
                            Confirmar Contraseña
                          </Label>
                          <Input
                            type="password"
                            value={ confirmPass }
                            onChange={(e)=>setConfirmPass(e.target.value)}
                            className="form-control"
                            placeholder="Confirma tu nueva contraseña"
                            id="confirm-password-input"
                          />
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="form-check">
                          <Input
                            className="form-check-input"
                            type="checkbox"
                            id="verification"
                          />
                          <Label
                            className="form-check-label"
                            htmlFor="verification"
                          >
                            Habilitar la verificación en dos pasos por correo
                            electrónico
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="mt-4 text-end">
                    <button type="submit" className="btn btn-primary">
                      Actualizar
                    </button>
                  </div>
                </Form>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
      <ToastContainer />
    </React.Fragment>
  );
};

export default RightSideContent;