import React, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { getUsers, getCompany,getEmployees } from '../../../services/userservice';
import { API_URL } from "../../../config";
import companyDefaultImage from "../../../assets/images/companyImg.jpg"
import defaultProfileImage from "../../../assets/images/user/img-02.jpg";


const LeftSideContent = ({ userId }) => {
  const [user, setUser] = useState("");
  const [perfil,setPerfil] = useState("");
  const [src,setSrc] = useState("");
  const ids = localStorage.getItem("id");
  var rol = localStorage.getItem("rol");

  let downloadImageURL = `${API_URL}download2/${perfil.photo}`;

  useEffect(() => {
    getUsers(ids)
      .then(data => setUser(data))
      .catch(error => console.error(error));
  }, [ids]);
  useEffect(() => {
  if (rol===1 || rol ==="1"){    
    getCompany(ids)
    .then(data => setPerfil(data))
    .catch(error => console.error(error));
  }else{
    getEmployees(ids)
    .then(data => setPerfil(data))
    .catch(error => console.error(error));
  }
  },[ids,rol])
  useEffect(()=>{
    if(perfil.photo){
      const timestamp = new Date().getTime();
      setSrc(`${downloadImageURL}?t=${timestamp}`);
    }else{
      if(rol === 1 || rol === '1'){
        setSrc(companyDefaultImage);
      }else{
        setSrc(defaultProfileImage);
      }
    }
  },[perfil])

  return (
    <React.Fragment>
      <Col lg={4}>
        <Card className="profile-sidebar me-lg-4">
          <CardBody className="p-4">
            <div className="text-center pb-4 border-bottom">
              <img
                src={src}
                style={{objectFit: "cover"}}
                alt=""
                className="avatar-lg img-thumbnail rounded-circle mb-4"
                onError={(e) => { rol === '1' ? e.target.src = companyDefaultImage: e.target.src = defaultProfileImage; }}

              />
              <h5 className="mb-0">{user ? user.username : "Cargando..."}</h5>
              <p className="text-muted">{user ? user.email : "Cargando..."}</p>
              {/* Resto del contenido */}
            </div>
            {/* Resto de las secciones */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
