import React, { useState, useEffect } from "react";
import { Col, Card, CardBody } from "reactstrap";

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

//Import Blog Images
import blogImage1 from "../../../assets/images/blog/img-01.jpg";
import blogImage3 from "../../../assets/images/blog/img-03.jpg";

//Import user Images
import { getEmployees3, getEducations, getExperiences } from "../../../services/userservice";

const images = [blogImage1, blogImage1, blogImage3];

const RightSideContent = () => {
  const [employee, setEmployee] = useState([]);
  const [education, setEducation] = useState([]);
  const [experience, setExperience] = useState([]);
  
  const [photoIndex, setphotoIndex] = useState(0);
  var employ = localStorage.getItem("employee");  

  const [isGallery, setisGallery] = useState(false);

  useEffect(() => {
    if (employ) {
      getEmployees3(employ)
        .then(data => setEmployee(data))
        .catch(error => console.error(error));
    }
  }, [employ]);

  useEffect(() => {
    if (employee.user_id) {
      getEducations(employee.user_id)
        .then(data => {
          const sortedEducation = data.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
          setEducation(sortedEducation);
        })
        .catch(error => console.error(error));
    }
  }, [employee.user_id]);

  useEffect(() => {
    if (employee.user_id) {
      getExperiences(employee.user_id)
        .then(data => {
          const sortedExperience = data.sort((a, b) => new Date(b.end_date) - new Date(a.end_date));
          setExperience(sortedExperience);
        })
        .catch(error => console.error(error));
    }
  }, [employee.user_id]);

console.log(education)

  return (
    <React.Fragment>
      {isGallery ? (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={true}
          onCloseRequest={() => {
            setisGallery(false);
          }}
          onMovePrevRequest={() => {
            setphotoIndex((photoIndex + images.length - 1) % images.length);
          }}
          onMoveNextRequest={() => {
            setphotoIndex((photoIndex + 1) % images.length);
          }}
          imageCaption={"Project " + parseFloat(photoIndex + 1)}
        />
      ) : null}
      <Col lg={8}>
        <Card className="candidate-details ms-lg-4 mt-4 mt-lg-0">
          <CardBody className="p-4 candidate-personal-detail">
            <div>
              <h6 className="fs-17 fw-semibold mb-3">Sobre mi</h6>
              <p className="text-muted mb-2">{employee.description}</p>
              <p className="text-muted mb-0"></p>
            </div>
            <Col lg={9}>
              <div className="candidate-education-details mt-4 pt-3">
                <h6 className="fs-17 fw-bold mb-0">Educación</h6>
                {education.map((jobListingDetails, key) => (
                  <div className="candidate-education-content mt-4 d-flex" key={key}>
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      {jobListingDetails.start_date.substring(0, 4)}
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1">{jobListingDetails.title}</h6>
                      <p className="mb-2 text-muted">
                        {jobListingDetails.institution} - ({jobListingDetails.start_date} - {jobListingDetails.end_date})
                      </p>
                      <p className="text-muted"></p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col lg={9}>
              <div className="candidate-education-details mt-4 pt-3">
                <h6 className="fs-17 fw-bold mb-0">Experiencias</h6>
                {experience.map((jobListingDetails, key) => (
                  <div className="candidate-education-content mt-4 d-flex" key={key}>
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                      {jobListingDetails.start_date.substring(0, 4)}
                    </div>
                    <div className="ms-4">
                      <h6 className="fs-16 mb-1">{jobListingDetails.company}</h6>
                      <p className="mb-2 text-muted">
                        {jobListingDetails.post} - ({jobListingDetails.start_date} - {jobListingDetails.end_date})
                      </p>
                      <p className="text-muted">{jobListingDetails.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            {/* <div className="candidate-portfolio mt-4 pt-3">
              <h6 className="fs-17 fw-bold mb-0">Projectos</h6>
              <Row>
                <Col lg={4} className="mt-4">
                  <div className="candidate-portfolio-box card border-0">
                    <Link to="#" className="image-popup">
                      <img
                        src={blogImage1}
                        onClick={() => {
                          setisGallery(true);
                          setphotoIndex(0);
                        }}
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={4} className="mt-4">
                  <div className="candidate-portfolio-box card border-0">
                    <Link to="#" className="image-popup">
                      <img
                        src={blogImage2}
                        onClick={() => {
                          setisGallery(true);
                          setphotoIndex(1);
                        }}
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </Col>
                <Col lg={4} className="mt-4">
                  <div className="candidate-portfolio-box card border-0">
                    <Link to="#" className="image-popup">
                      <img
                        src={blogImage3}
                        onClick={() => {
                          setisGallery(true);
                          setphotoIndex(1);
                        }}
                        alt=""
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                </Col>
              </Row>
            </div> */}
            {/* <div className="mt-4 pt-3">
              <div className="d-sm-flex align-items-top">
                <div className="flex-shrink-0">
                  <img
                    className="rounded-circle avatar-md img-thumbnail"
                    src={userImage4}
                    alt="img"
                  />
                </div>
                <div className="flex-grow-1 ms-sm-3">
                  <div>
                    <p className="text-muted float-end fs-14 mb-2">
                      Jun 23, 2021
                    </p>
                    <h6 className="mt-sm-0 mt-3 mb-1">Michelle Durant</h6>
                    <div className="text-warning review-rating mb-2">
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star-half-full"></i>
                    </div>
                    <p className="text-muted fst-italic">
                      " There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour "
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-sm-flex align-items-top mt-4">
                <div className="flex-shrink-0">
                  <img
                    className="rounded-circle avatar-md img-thumbnail"
                    src={userImage2}
                    alt="img"
                  />
                </div>
                <div className="flex-grow-1 ms-sm-3">
                  <div>
                    <p className="text-muted float-end fs-14 mb-2">
                      Jun 25, 2021
                    </p>
                    <h6 className="mt-sm-0 mt-3 mb-1">Jeffrey Montgomery</h6>
                    <div className="text-warning review-rating mb-2">
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star"></i>
                      <i className="mdi mdi-star-half-full"></i>
                      <i className="mdi mdi-star-outline"></i>
                    </div>
                    <p className="text-muted fst-italic">
                      " There are many variations of passages of Lorem Ipsum
                      available, but the majority have suffered alteration in
                      some form, by injected humour "
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            {/* <form action="#" className="mt-4 pt-3">
              <h6 className="fs-17 fw-semibold mb-2">Agrega una reseña</h6>
              <p className="text-muted mb-3">Su calificación para este listado</p>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="inputname" className="form-label">
                      Nombre completo
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputname"
                      placeholder="Nombre completo"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="inputemail" className="form-label">
                      Email
                    </label>
                    <Input
                      type="email"
                      className="form-control"
                      id="inputemail"
                      placeholder="email"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="inputsubject" className="form-label">
                      Asunto
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputsubject"
                      placeholder="Asunto"
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="inputcoment" className="form-label">
                      Cometanrio
                    </label>
                    <textarea
                      className="form-control"
                      id="inputcoment"
                      rows="3"
                      placeholder="Agregar Cometanrio"
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <div className="text-end">
                <button type="submit" className="btn btn-primary btn-hover">
                  Enviar <i className="uil uil-angle-right-b"></i>
                </button>
              </div>
            </form> */}
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RightSideContent;
