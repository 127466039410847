import React, {useState} from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";

//Import Image
import lightLogo from "../../assets/images/Avanza_Empleo.png";
import darkLogo from "../../assets/images/Avanza_Empleo.png";
import { useNavigate } from "react-router-dom";
import signInImage from "../../assets/images/auth/sign-in.png";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getLogin, getEmployees } from '../../services/userservice';
import login_button from "../../assets/images/buttons/login_button.png"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Utiliza el hook
  const registro = localStorage.getItem("registro");
  var parrafo ="Ingresa a tu cuenta registrada con tu correo electrónico o número de identificación y contraseña.";
  if (registro==='registro'){
    parrafo = "Usuario Creado con exito por favor ingrese";
    localStorage.setItem("registro","");
  }
  
  document.title = "Avanza Empleo | Iniciar Sesión";

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const user = await getLogin(email, password);

      localStorage.setItem("nombre", user.username);
      localStorage.setItem("email", user.email);
      localStorage.setItem("id", user.id);
      localStorage.setItem("rol", user.role_id);

      //Si el rol es employee
      if(user.role_id === 2){
        try {
          const employeeInfo = await getEmployees(user.id);
          localStorage.setItem("employeeId", employeeInfo.id);
        } catch (err) {

          // Mostrar un mensaje más informativo y redirigir con retraso
          toast.info("Debe completar su perfil para utilizar toda la aplicación. Será redirigido a la página de perfil.");

          // Esperar 3 segundos antes de redirigir
          setTimeout(() => {
            navigate('/myProfile'); // Redirigir a la página de perfil
          }, 3000); // 3000 milisegundos = 3 segundos

          return false; // Salir de la función
        }

      }
      navigate('/'); // Redirige al usuario a la página principal
      return true;
    } catch (err) {
      toast.error("Error al iniciar sesión, por favor inténtalo de nuevo.");
    }
  };


  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                                style={{ width: "180px" }}
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                                style={{ width: "180px" }}
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>BIENVENIDO DE NUEVO</h5>
                                {parrafo && <p className="text-white-70">{parrafo}</p>}
                              </div>
                              <Form action="/" className="auth-form" onSubmit={handleSubmit}>
                                <div className="mb-3">
                                  <label
                                    htmlFor="usernameInput"
                                    className="form-label"
                                  >
                                    Usuario
                                  </label>
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="usernameInput"
                                    placeholder="Correo electronico"
                                    required
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                  />
                                </div>
                                <div className="mb-3">
                                  <label
                                    htmlFor="passwordInput"
                                    className="form-label"
                                  >
                                    Contraseña
                                  </label>
                                  <Input
                                    type="password"
                                    className="form-control"
                                    id="passwordInput"
                                    placeholder="Contraseña"
                                    required
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                  />
                                </div>
                                <div className="mb-4">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id="flexCheckDefault"
                                    />
                                    <Link
                                      to="/resetpassword"
                                      className="float-end text-white"
                                    >
                                      ¿Has olvidado tu contraseña?
                                    </Link>
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Recordarme
                                    </label>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <button
                                    type="submit"
                                    style={{
                                      background: 'none',
                                      border: 'none',
                                      padding: 0,
                                      cursor: 'pointer'
                                    }}
                                  >
                                    <img src={login_button} height="50.5" alt="Iniciar Sesión" className="logo-dark" />
                                  </button>
                                </div>
                              </Form>
                              <div className="mt-4 text-center">
                                <p className="mb-0">
                                  ¿No tengo cuenta?{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline fs-4"
                                  >
                                    {" "}
                                    Registrate Aquí{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default SignIn;
