import React from "react";
import { Link } from "react-router-dom";

//import UserImage
import userImage3 from "../../../assets/images/user/img-03.jpg";

const BlogColumn = () => {
  return (
    <React.Fragment>
      <ul className="list-inline mb-0 mt-3 text-muted">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <img
                src={userImage3}
                alt=""
                className="avatar-sm rounded-circle"
              />
            </div>
            <div className="ms-3">
              <Link to="/blogauther" className="primary-link">
                <h6 className="mb-0">Por Jose Luis</h6>
              </Link>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="uil uil-calendar-alt"></i>
            </div>
            <div className="ms-2">
              <p className="mb-0"> Jun 10, 2023</p>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className="uil uil-comments-alt"></i>
            </div>
            <div className="ms-2 flex-grow-1">
              <p className="mb-0"> 2 Comentarios</p>
            </div>
          </div>
        </li>
      </ul>
      <div className="mt-4">
        <h5>¿Qué hace que el mejor espacio de trabajo conjunto?</h5>
        <p className="text-muted">
          Buscar objetivamente diversos catalizadores para el cambio para la interoperabilidad
          metaservicios. Metaservicios revolucionarios de reingeniería distintiva
          y arquitecturas premium. Incubar intrínsecamente intuitivo
          oportunidades y potencialidades en tiempo real. comunicar apropiadamente
          Tecnología uno a uno.
        </p>
        <p className="text-muted mb-4">
          Renovaciones del hogar, especialmente aquellas que implican una gran cantidad de demolición.
          puede ser un asunto muy polvoriento. Lo mismo es cierto cuando experimentamos la
          sensacin emocional de estrs desde nuestras primeras instancias de social
          burla de rechazo. Rápidamente aprendemos a temer y por lo tanto automáticamente
          Evitar situaciones potencialmente estresantes de todo tipo, incluidas las
          más común de todos cometer errores.
        </p>
        <figure className="blog-blockquote text-center">
          <blockquote className="blockquote">
            <p className="fs-17">
              "Una agencia de consultoría de negocios está involucrada en la planificación,
              implementación y educación de las empresas”.
            </p>
          </blockquote>
          <figcaption className="blockquote-footer fs-15 mb-4">
            Agencia creativa
            <cite title="Source Title" className="text-primary fw-semibold">
              {" "}
              Alice Mellor
            </cite>
          </figcaption>
        </figure>
        <p className="text-muted">
          Si los espíritus del artículo nuevos la cubrieron apresuradamente sentándola. Dinero
          libros ingeniosos ni son añadir. La edad del pollo tuvo la tarde creer pero continuar
          pretender señora. En el consejo perdido, mi hermana no. La señorita le dijo a Jamón que sabía
          verla manchar cerca de lata. Espíritu toda su llamada.
        </p>
        <p className="text-muted">
          La ventaja de su origen latino y la relativa falta de sentido de
          Lorum Ipsum es que el texto no llama la atención sobre sí mismo ni
          distraer la atención del espectador del diseño.
        </p>
        <div className="d-flex align-items-center mb-4">
          <div className="flex-shrink-0">
            <b>Tags:</b>
          </div>
          <div className="flex-grow-1 ms-2 d-flex flex-wrap align-items-start gap-1">
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Negocios
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Diseño
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Creativo
            </Link>
            <Link
              to="#"
              className="badge bg-success-subtle text-success mt-1 fs-14"
            >
              Eventos
            </Link>
          </div>
        </div>
        <ul className="blog-social-menu list-inline mb-0 text-end">
          <li className="list-inline-item">
            <b>Compartir post:</b>
          </li>
          <li className="list-inline-item">
            <Link to="#" className="social-link bg-primary-subtle text-primary">
              <i className="uil uil-facebook-f"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="#" className="social-link bg-success-subtle text-success">
              <i className="uil uil-whatsapp"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="#" className="social-link bg-blue-subtle text-blue">
              <i className="uil uil-linkedin-alt"></i>
            </Link>
          </li>
          <li className="list-inline-item">
            <Link to="#" className="social-link bg-danger-subtle text-danger">
              <i className="uil uil-envelope"></i>
            </Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default BlogColumn;
