export const nationalityList = [{tipo:"Seleccione una nacionalidad"},
    {tipo:'Colombiano/a'},
    {tipo:'Argentino/a'},
    {tipo:'Boliviano/a'},
    {tipo:'Chileno/a'},
    {tipo:'Costarricense'},
    {tipo:'Cubano/a'},
    {tipo:'Dominicano/a'},
    {tipo:'Ecuatoriano/a'},
    {tipo:'Español/española'},
    {tipo:'Guatemalteco/a'},
    {tipo:'Hondureño/a'},
    {tipo:'Mexicano/a'},
    {tipo:'Nicaragüense(s)'},
    {tipo:'Panameño/a'},
    {tipo:'Paraguayo/a'},
    {tipo:'Peruano/a'},
    {tipo:'Puertoriqueño/a'},
    {tipo:'Salvadoreño/a'},
    {tipo:'Uruguayo/a'},
    {tipo:'Venezolano/a'}]
export const sexoList = [{tipo:"Seleccione una opción"},{tipo:"Femenino"},{tipo:"Masculino"},{tipo:"Prefiero no mencionarlo"},{tipo:"No aplica"}]
export const identificationOptions = [ {identification_value:"Seleccione un tipo de identificación"},{identification_value:"Cédula de ciudadanía"},
    {identification_value:"Cédula de extranjería"},
    {identification_value:"Pasaporte"},
    {identification_value:"Permiso Especial de Permanencia - PEP"},
    {identification_value:"Permiso temporal de permanencia"},
    {identification_value:"PPT/RUMV"},
    {identification_value:"Tarjeta de identidad"}]
export const studyLevelList = [{study:"Seleccione una opción"},{study:"No aplica"},{study:"Primaria"},{study:"Bachiller"},{study:"Técnico"},{study:"Tecnólogo"},{study:"Profesional"},{study:"Especialista"},{study:"Maestría"},{study:"Doctorado"}]
export const languajeList = [
    {valor:'Seleccione un idioma'},
    {valor:'Alemán'},
    {valor:'Árabe Egipcio Hablado'},
    {valor:'Árabe Moderno Estándar'},
    {valor:'Bengalí'},
    {valor:'Canarés'},
    {valor:'Chino Mandarín'},
    {valor:'Chino Wu'},
    {valor:'Chino Yue'},
    {valor:'Coreano'},
    {valor:'Español'},
    {valor:'Francés'},
    {valor:'Gujarati'},
    {valor:'Hausa'},
    {valor:'Hindi'},
    {valor:'Indonesio'},
    {valor:'Inglés'},
    {valor:'Italiano'},
    {valor:'Japonés'},
    {valor:'Javanés'},
    {valor:'Marathi'},
    {valor:'Persa Iraní (Farsi)'},
    {valor:'Pidgin Nigeriano'},
    {valor:'Portugués'},
    {valor:'Punjabi Occidental'},
    {valor:'Ruso'},
    {valor:'Swahili'},
    {valor:'Tagalo'},
    {valor:'Tailandés'},
    {valor:'Tamil'},
    {valor:'Telugu'},
    {valor:'Turco'},
    {valor:'Urdu'},
    {valor:'Vietnamita'}]
export const salaryRange = [
    {valor:"Seleccione una opción"},
    {valor:'$1.300.000 A $1.500.000'},
    {valor:'$1.600.000 A $2.000.000'},
    {valor:'$2.100.000 A $2.500.000'},
    {valor:'$2.600.000 A $3.000.000'},
    {valor:'$3.100.000 A $3.500.000'},
    {valor:'$3.600.000 A $4.000.000'},
    {valor:'$4.100.000 A $4.500.000'},
    {valor:'$4.600.000 A $5.000.000'},
    {valor:'$5.100.000 A $6.000.000'},
    {valor:'$6.100.000 A $7.000.000'},
    {valor:'$7.100.000 A $8.000.000'},
    {valor:'$8.100.000 A $9.000.000'},
    {valor:'MÁS DE $9.000.000'}]
export const contractTypeList = ["Contrato a Termino Fijo","Contrato a Termino Indefinido","Contrato por Obra Labor","Contrato medio tiempo","Contrato por prestación de servicios","Contrato por horas"]
export const availableTimeList = ["Tiempo Completo", "Medio tiempo","Por Días","Por horas"]
