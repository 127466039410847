import { Col, Container, Row } from "reactstrap";
import JobGridHeader from "./JobGridHeader";
import JobVacancy from "./JobVacancy";
import Section from "./Section";
import Selected from "./Selected";
import Pagination from "../JobList2/Pagination";
import React, { useState } from "react";

const JobGrid = () => {
  document.title = "Avanza | Empleos";
  
  const [filters, setFilters] = useState({
    companyName: "",
    location: "",
    jobType: ""
  });

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <JobGridHeader setFilters={setFilters} />
          <Selected />
          <Row>
            <Col lg={12}>
              <JobVacancy filters={filters} />
            </Col>
          </Row>
          <Pagination />
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobGrid;
