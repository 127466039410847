import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const PrivacyAndPolicyPage = () => {
  const privacyandpolicyPage = [
    {
      id: 1,
      policyTitle: "Uso por Avanza Empleo",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make"
        },
        {
          id: 2,
          policyInnerRule:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          id: 3,
          policyInnerRule:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make."
        },
        {
          id: 4,
          policyInnerRule:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make"
        }
      ]
    },
    {
      id: 2,
      policyTitle: "La informacion es usada para :",
      policyRules: [
        {
          id: 1,
          policyInnerRule: "  Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          id: 2,
          policyInnerRule: " OLorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          id: 3,
          policyInnerRule:
            " Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        },
        {
          id: 4,
          policyInnerRule: " Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        }
      ]
    },
    {
      id: 3,
      policyTitle: "Privacidad y protección de derechos de autor",
      policyRules: [
        {
          id: 1,
          policyInnerRule:
            '  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make.'
        },
        {
          id: 2,
          policyInnerRule:
            "  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make"
        }
      ]
    }
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            {privacyandpolicyPage.map((privacyandpolicyDetails, key) => (
              <Col lg={12} key={key}>
                <h5 className="mb-4">{privacyandpolicyDetails.policyTitle}</h5>
                <ul className="about-list list-unstyled text-muted mb-4 pb-2">
                  {privacyandpolicyDetails.policyRules.map(
                    (privacyandpolicyInner, key) => (
                      <li key={key}>{privacyandpolicyInner.policyInnerRule}</li>
                    )
                  )}
                </ul>
              </Col>
            ))}
            <div className="text-end">
              <Link to="#" className="btn btn-primary">
                <i className="uil uil-print"></i> Imprimir
              </Link>
            </div>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default PrivacyAndPolicyPage;
