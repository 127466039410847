import React from "react";
import { Form } from "react-bootstrap";
import { Col, Container, Input, Row } from "reactstrap";
import Countdown from "react-countdown";

//import Image
import rocketImage from "../../assets/images/animat-rocket-color.gif";

const ComingSoon = () => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span>Tiempo para el gran Lanzamiento</span>;
    } else {
      return (
        <>
          <div className="countdownlist-item">
            <div className="count-title">Dias</div>
            <div className="count-num">{days}</div>
          </div>
          <div className="countdownlist-item">
            <div className="count-title">Horas</div>
            <div className="count-num">{hours}</div>
          </div>
          <div className="countdownlist-item">
            <div className="count-title">Minutos</div>
            <div className="count-num">{minutes}</div>
          </div>
          <div className="countdownlist-item">
            <div className="count-title">Segundos</div>
            <div className="count-num">{seconds}</div>
          </div>
        </>
      );
    }
  };
  document.title = "Avanza - Lanzamiento";
  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-coming-soon bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={6}>
                    <div className="text-center">
                      <div className="mb-4 pb-3">
                        <img
                          src={rocketImage}
                          alt=""
                          height="150"
                          className="mg-fluid"
                        />
                      </div>
                      <h1>Estamos lanzando pronto..!!</h1>
                      <p className="text-muted mb-4 pb-3">
                        Espera el lanzamiento de avanza empleo, para que puedas disfrutar de todas las funcionalidades que tenemos para ti.
                      </p>
                      <div id="countdown" className="d-flex">
                        <Countdown date="2023/12/31" renderer={renderer} />
                      </div>
                      <Form action="#" className="coming-soon-subacribe mt-4">
                        <div className="input-group mb-3">
                          <Input
                            type="text"
                            className="form-control text-dark"
                            placeholder="Email"
                          />
                          <button className="btn btn-primary" type="button">
                            Enviar
                          </button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ComingSoon;
