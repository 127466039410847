import React, { useState, useEffect } from "react";
import { Card, CardBody, Col } from "reactstrap";
import { API_URL } from "../../../config";
import defaultUserImg from "../../../assets/images/user/img-02.jpg";
import { getDocument, getEmployees3 } from "../../../services/userservice";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeftSideContent = () => {
  
  const [employee, setEmployee] = useState([]);
  var employ = localStorage.getItem("employee");  
  const [documento, setDocumento] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (employ) {
      getEmployees3(employ)
        .then(data => {
          setEmployee(data);
          setUserId(data.user_id);
        })
        .catch(error => {
          console.error(error);
          toast.error("Error al obtener empleados.");
        });
    }
  }, [employ]);

  useEffect(() => {
    if (userId) {
      getDocument(userId)
        .then(data => {
          if(data.length > 0){
            setDocumento(data[0].name);
          }
        })
        .catch(error => {
          console.error(error);
          toast.error("Error al obtener el documento.");
        });
    }
  }, [userId]);

  const downloadFile = () => {
    if(documento === ""){
      toast.error("El usuario no ha cargado un documento aún");
      return;
    }
    fetch(`${API_URL}download/${documento}`, {
      method: 'GET',
      headers: {
        // Tus headers aquí si los necesitas
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.blob().then(blob => ({
        blob: blob,
        contentType: response.headers.get("content-type")
      }));
    })
    .then(({ blob, contentType }) => {
      if (contentType.includes("application/json")) {
        throw new Error('File not found');
      }
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Hoja de vida ' + employee.name + " " + employee.lastname;  // Cambia esto al nombre de archivo que quieras
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch(error => {
      console.error(error);
      toast.error("Error al descargar el archivo.");
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Col lg={4}>
        <Card className="side-bar">
          <CardBody className="p-4">
            <div className="candidate-profile text-center">
              <img
                src={`${API_URL}download2/${employee.photo}`}
                alt=""
                className="avatar-lg rounded-circle"
                onError={(e) => { e.target.src = defaultUserImg; }}
              />
              <h6 className="fs-18 mb-0 mt-4">{employee.name + " " + employee.lastname}</h6>
              <p className="text-muted mb-4">Candidato</p>
              {/* <ul className="candidate-detail-social-menu list-inline mb-0">
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-twitter-alt"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-whatsapp"></i>
                  </Link>
                </li>
                <li className="list-inline-item">
                  <Link to="#" className="social-link">
                    <i className="uil uil-phone-alt"></i>
                  </Link>
                </li>
              </ul> */}
            </div>
          </CardBody>

          <CardBody className="candidate-profile-overview border-top p-4">
            <h6 className="fs-17 fw-semibold mb-3">Informacion General</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <div className="d-flex">
                  <label className="text-dark">Ubicacion</label>
                  <div>
                    <p className="text-muted mb-0">{employee.country} / {employee.city}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Aspiración Salarial</label>
                  <div>
                    <p className="text-muted mb-0">{employee.salary} / Mes</p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="d-flex">
                  <label className="text-dark">Idiomas</label>
                  <div>
                    <p className="text-muted mb-0">
                      Ingles, Turco, Japonés
                    </p>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <div className="d-flex">
                  <label className="text-dark">Experiencia</label>
                  <div>
                    <p className="text-muted mb-0">3 Años</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <label className="text-dark">Estudios</label>
                  <div>
                    <p className="text-muted mb-0">Ingenieria</p>
                  </div>
                </div>
              </li> */}
              {/* <li>
                <div className="d-flex">
                  <label className="text-dark">Views</label>
                  <div>
                    <p className="text-muted mb-0">2574</p>
                  </div>
                </div>
              </li> */}
            </ul>
            <div className="mt-3">
              <a href={`https://wa.me/57${employee.phone}`} className="btn btn-danger btn-hover w-100" target="_blank" rel="noopener noreferrer">
                <i className="uil uil-whatsapp"></i> Contactar por WhatsApp
              </a>
              <button onClick={downloadFile} download className="btn btn-primary btn-hover w-100 mt-2">
                <i className="uil uil-import"></i> Descargar CV
              </button>
            </div>
            {/* <ul className="list-inline d-flex justify-content-between align-items-center mb-0 mt-2">
              <li className="list-inline-item text-warning review-rating">
                <i className="mdi mdi-star"></i>
                <i className="mdi mdi-star"></i>
                <i className="mdi mdi-star"></i>
                <i className="mdi mdi-star"></i>
                <i className="mdi mdi-star-half-full"></i>
              </li>
              <li className="list-inline-item">
                <div className="favorite-icon">
                  <Link to="#">
                    <i className="uil uil-heart-alt fs-18"></i>
                  </Link>
                </div>
              </li>
            </ul> */}
          </CardBody>
          {/* <CardBody className="p-4 border-top">
            <h6 className="fs-17 fw-semibold mb-3">Habilidades</h6>
            <div className="d-flex flex-wrap align-items-start gap-1">
              <span className="badge bg-success-subtle text-success fs-13 mt-1">
                Diseño de interfaz de usuario
              </span>
              <span className="badge bg-success-subtle text-success fs-13 mt-1">
                Diseño Web
              </span>
              <span className="badge bg-success-subtle text-success fs-13 mt-1">
                Diseño de aplicaciones móviles
              </span>
            </div>
          </CardBody> */}
          <CardBody className="candidate-contact-details p-4 border-top">
            <h6 className="fs-17 fw-semibold mb-3">Detalles de Contacto</h6>
            <ul className="list-unstyled mb-0">
              {/* <li>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                    <i className="uil uil-envelope-alt"></i>
                  </div>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Email</h6>
                    <p className="text-muted mb-0">gabrielpalmer@gmail.com</p>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                    <i className="uil uil-map-marker"></i>
                  </div>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Direccion</h6>
                    <p className="text-muted mb-0">{employee.adress}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                    <i className="uil uil-phone"></i>
                  </div>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Telefono</h6>
                    <p className="text-muted mb-0">+57 {employee.phone}</p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="d-flex align-items-center mt-4">
                  <div className="icon bg-primary-subtle text-primary flex-shrink-0">
                    <i className="uil uil-skype-alt"></i>
                  </div>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-1">Skype</h6>
                    <p className="text-muted mb-0">@gabrielpalmer</p>
                  </div>
                </div>
              </li> */}
            </ul>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default LeftSideContent;
