import React, { useState } from "react";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { forgot_pass } from "../../services/userservice"; 
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Import Image
import lightLogo from "../../assets/images/Avanza_Empleo.png";
import darkLogo from "../../assets/images/Avanza_Empleo.png";
import resetPasswordImage from "../../assets/images/auth/reset-password.png";

const ResetPassword = () => {
  document.title = "Avanza - Recuperar Contraseña";
  
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    forgot_pass(email).then(data=>{
      toast.success("¡Solicitud enviada exitosamente!");
    })
    .catch(error => {
      console.error(error)
      toast.success("¡Ha ocurrido un error inesperado, comuniquese con soporte!");

    })

  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row className="g-0">
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                                style={{ width: "180px" }}
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                                style={{ width: "180px" }}
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={resetPasswordImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <CardBody className="auth-content p-5 h-100 text-white">
                            <div className="text-center mb-4">
                              <h5>Restablecer la contraseña</h5>
                              <p className="text-white-50">
                                Restablece tu contraseña con Avanza Empleo.
                              </p>
                            </div>
                            <Form className="auth-form text-white" onSubmit={handleResetPassword}>
                              <div
                                className="alert alert-warning text-center mb-4"
                                role="alert"
                              >
                                {" "}
                                Ingrese su correo electrónico y se enviarán las instrucciones
                                ¡A usted!{" "}
                              </div>
                              <div className="mb-4">
                                <label className="form-label" htmlFor="email">
                                  Usuario / Email
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="email"
                                  placeholder="Usuario / Email"
                                  value={email}
                                  onChange={handleEmailChange}
                                />
                              </div>
                              <div className="mt-3">
                                <button
                                  type="submit"
                                  className="btn btn-white w-100"
                                >
                                  Send Request
                                </button>
                              </div>
                            </Form>
                            <div className="mt-5 text-center text-white-50">
                              <p>
                                ¿Lo recordabas?{" "}
                                <Link
                                  to="/signin"
                                  className="fw-medium text-white text-decoration-underline"
                                >
                                  {" "}
                                  Ir a Iniciar sesión{" "}
                                </Link>
                              </p>
                            </div>
                          </CardBody>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
