import React from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const Categories = () => {
  const categories = [
    {
      id: 1,
      jobCategories: [
        {
          id: 1,
          jobName: "Administrativo",
          jobNumbers: 25
        },
        {
          id: 2,
          jobName: "Entidades financieras",
          jobNumbers: 10
        },
        {
          id: 3,
          jobName: "Proyectos, investigación y calidad",
          jobNumbers: 71
        },
        {
          id: 4,
          jobName: "Aseo y seguridad privada",
          jobNumbers: 40
        },
        {
          id: 5,
          jobName: "Ingeniería",
          jobNumbers: 86
        },
        {
          id: 6,
          jobName: "Restaurantes, hostelería turismo",
          jobNumbers: 47
        },
        {
          id: 7,
          jobName: "Compras, adquisición y comercio exterior",
          jobNumbers: 47
        }
      ]
    },
    {
      id: 2,
      jobCategories: [
        {
          id: 1,
          jobName: "Legal y jurídica",
          jobNumbers: 120
        },
        { id: 2, jobName: "Talento humano", jobNumbers: 73 },
        { id: 3, jobName: "Contabilidad y finanzas", jobNumbers: 88 },
        {
          id: 4,
          jobName: "Logística, bodega, almacenamiento",
          jobNumbers: 10
        },
        {
          id: 5,
          jobName: "Tecnología e informática, telecomunicaciones, software",
          jobNumbers: 55
        },
        {
          id: 6,
          jobName: "Deportistas y entrenadores físicos",
          jobNumbers: 99
        },
        {
          id: 7,
          jobName: "Mantenimiento y reparaciones técnicas",
          jobNumbers: 27
        },
      ]
    },
    {
      id: 3,
      jobCategories: [
        {
          id: 1,
          jobName: "Ventas, mercadeo, publicidad, comunicación, servicio al cliente y Call center",
          jobNumbers: 175
        },
        {
          id: 2,
          jobName: "Diseño, artes gráficas y teatro",
          jobNumbers: 60
        },
        {
          id: 3,
          jobName: "Medicina y salud",
          jobNumbers: 42
        },
        {
          id: 4,
          jobName: "Docencia",
          jobNumbers: 30
        },
        {
          id: 5,
          jobName: "Producción operativa y empaque (jefes, lideres y operarios)",
          jobNumbers: 120
        },
      ]
    }
  ];
  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="text-center mb-5">
                <p className="badge bg-info fs-14 mb-2">Oportunidades de empleo</p>
                <h4>Buscar Por Categoria</h4>
                <p className="text-muted">
                Selecciona la categoría que se ajusta a tu perfil profesional, así encontraras de manera ágil las ofertas de empleo que más se relacionan a tu experiencia laboral.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            {categories.map((categoriesDetails, key) => (
              <Col lg={4} key={key}>
                <Card className="job-Categories-box bg-light border-0">
                  <CardBody className="p-4">
                    <ul className="list-unstyled job-Categories-list mb-0">
                      {(categoriesDetails.jobCategories || []).map(
                        (jobCategoriesDetails, key) => (
                          <li key={key} className="job-category-item">
                            <Link to="/joblist" className="primary-link d-flex justify-content-between align-items-center">
                              {jobCategoriesDetails.jobName}
                              <span className="badge bg-info-subtle text-info">
                                {jobCategoriesDetails.jobNumbers}
                              </span>
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default Categories;
