import React from "react";
import { Container, Row, Col } from "reactstrap";
// import { Link } from "react-router-dom";

//Import Images
import AboutImage from "../../../assets/images/about/img-01.jpg";

const About = () => {
  return (
    <React.Fragment>
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">Nosotros</h6>
                <h2 className="title mb-4">
                  Somos aliado <span className="text-info fw-bold">estrategico</span>{" "}
                  para la empresas y candidatos en el territorio Vallecaucano.
                </h2>
                <p className="text-muted">
                Somos un aliado estrategico para las empresas que se encuentran ubicadas en el territorio Vallecaucano  en todos los sectores industriales y comerciales, enfocandonos en las areas mas importantes de su organización. 
                Gestionamos la busqueda del mejor talento para su empresa y facilitamos su proceso de selección y contratación. Contamos con amplia cobertura en todas las ciudades, urbanizaciones, corregimientos de la Región destacandonos por tener candidatos con  información actualizada y vigente.
                </p>

                {/* <Row mt={4} pt={2}>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted mb-0 mb-md-3">
                      <li> Digital Marketing Solutions</li>
                      <li> Our Talented & Experienced Marketing Agency</li>
                      <li> Creative Design</li>
                      <li> New jobs Innovation</li>
                    </ul>
                  </Col>
                  <Col md={6}>
                    <ul className="list-unstyled about-list text-muted">
                      <li> Create Resume</li>
                      <li> 5000+ Comnpanies</li>
                      <li> Our Blog</li>
                      <li> and more...</li>
                    </ul>
                  </Col>
                </Row> */}
                {/* <div className="mt-3">
                  <Link to="#" className="btn btn-primary btn-hover">
                    Learn More{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>
                </div> */}
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img src={AboutImage} alt="" className="img-fluid rounded" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default About;
