import React, { useEffect } from "react";
import lightLogo from "../../assets/images/Avanza_Empleo.png";
import darkLogo from "../../assets/images/Avanza_Empleo.png";
import signInImage from "../../assets/images/auth/sign-in.png";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useNavigate, Link } from "react-router-dom";

const SignOut = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Verifica el contenido del token si es necesario
    const token = localStorage.getItem("token");
    console.log("Token actual:", token);

    // Elimina el token
    localStorage.removeItem("token");

    // Verifica el nombre de usuario antes y después de cerrar sesión
    const usernameBefore = localStorage.getItem("nombre");
    console.log("Nombre de usuario antes de cerrar sesión:", usernameBefore);

    // Elimina otros datos del usuario si es necesario
    localStorage.removeItem("rol");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("nombre");
    localStorage.removeItem("employeeId");

    // Verifica el nombre de usuario después de cerrar sesión
    const usernameAfter = localStorage.getItem("nombre");
    console.log("Nombre de usuario después de cerrar sesión:", usernameAfter);

    // Redirige a la página de inicio de sesión
    navigate("/signin");
  }, [navigate]);

  document.title = "Avanza Empleo | Cerrar Sesión";

  return (
    <React.Fragment>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    <Card className="auth-box">
                      <Row>
                        <Col lg={6} className="text-center">
                          <CardBody className="p-4">
                            <Link to="/">
                              <img
                                src={lightLogo}
                                alt=""
                                className="logo-light"
                                style={{ width: "180px" }}
                              />
                              <img
                                src={darkLogo}
                                alt=""
                                className="logo-dark"
                                style={{ width: "180px" }}
                              />
                            </Link>
                            <div className="mt-5">
                              <img
                                src={signInImage}
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </CardBody>
                        </Col>
                        <Col lg={6}>
                          <div className="auth-content card-body p-5 text-white">
                            <div className="w-100">
                              <div className="text-center mb-4">
                                <h5>Estás Desconectado</h5>
                                <p className="text-white-70">
                                  Gracias por usar Avanza Empleo
                                </p>
                              </div>
                              <Link
                                to="/signin"
                                className="btn btn-white btn-hover w-100"
                              >
                                Iniciar Sesión
                              </Link>
                              <div className="mt-3 text-center">
                                <p className="mb-0">
                                  No tengo Cuenta{" "}
                                  <Link
                                    to="/signup"
                                    className="fw-medium text-white text-decoration-underline"
                                  >
                                    {" "}
                                    Resgistrame{" "}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignOut;
