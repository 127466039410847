import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getCategory } from "../../../services/userservice"; // Asegúrate de tener esta función implementada

const JobType = ({ value, onChange }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    // Llamada al endpoint para obtener las categorías
    getCategory()
      .then(data => {
        // Transformar los datos para el componente Select
        const categories = data.map(category => ({
          label: category.description,
          value: category.id
        }));
        setOptions(categories);
      })
      .catch(error => console.error("Error fetching categories: ", error));
  }, []);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      border: 0,
      boxShadow: "none",
      padding: "12px 0 12px 40px",
      margin: "-16px -6px 0 -52px",
      borderRadius: "0"
    })
  };

  return (
    <React.Fragment>
      <Select
        options={options}
        styles={colourStyles}
        className="selectForm__inner"
        value={value}
        onChange={onChange}
        name="choices-single-categories"
        id="choices-single-categories"
        aria-label="Default select example"
      />
    </React.Fragment>
  );
};

export default JobType;
