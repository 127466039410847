import axios from 'axios';
import { API_URL } from '../config';



const instance = axios.create({
  baseURL: API_URL,
  // baseURL: 'https://interfaz.avanzaempleo.com.co',
  httpsAgent: {
    rejectUnauthorized: false,
  },
});

export const getUsers = async (id) => {
  try {
    const response = await instance.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getLogin = async (email,password) => {
  try {
    const response = await instance.get(`/login/${email}/${password}`);
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postUser = async (user, email, password, rol) => {
  if (rol === null || rol === '' || isNaN(rol)) {
    rol = "1";
  } // Imprimirá "string"
  rol = parseInt(rol);
  try {
    const response = await instance.post('/users', {
      username: user,
      email: email,
      password: password,
      role_id: rol // Asegúrate de que esto sea un número, ya que en el JSON se muestra como 0
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putUserPass = async (id, password) => {

  try {
    const response = await instance.put(`/updateUser/${id}`, {
      password: password
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployees = async (id) => {
  try {
    const response = await instance.get(`/employees/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployees3 = async (id) => {
  try {
    const response = await instance.get(`/employees2/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployees2 = async () => {
  try {
    const response = await instance.get(`/employees`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};




export const postEmployees = async (employeData) => {
  
  const userId = localStorage.getItem("id");
  var rol = localStorage.getItem("rol")

  if(rol===2||rol==="2"){
    try {
        
        await instance.get(`/employees/${userId}`);
        await instance.put(`/employees/${employeData.id}`, {
            name: employeData.name, 
            lastname: employeData.lastname,
            phone: employeData.phone,
            description: employeData.description,
            salary: employeData.salary,
            country: employeData.country,
            city: employeData.city,
            department: employeData.departmen,
            adress: employeData.adress,
            photo: employeData.photo,
            user_id: userId,
            identificationNumber:employeData.identificationNumber,
            typeIdentification:employeData.typeIdentification,
            sexo:employeData.sexo,
            nationality:employeData.nationality,
            studyLevel:employeData.studyLevel,
            birthDate:employeData.birthDate,
            neighborhood:employeData.neighborhood,
            languaje: employeData.languaje,
            first_job:employeData.first_job,
        });
      // if (response.data !== null && response.data !== ''){
        
      
    } catch (error) {
      // Puedes verificar el código de estado o el mensaje de error para saber si debes actualizar
        try {
        
            await instance.post('/employees', { 
              name: employeData.name, 
              lastname: employeData.lastname,
              phone: employeData.phone,
              description: employeData.description,
              salary: employeData.salary,
              country: employeData.country,
              city: employeData.city,
              department: employeData.departmen,
              adress: employeData.adress,
              photo: employeData.photo,
              user_id: userId,
              identificationNumber:employeData.identificationNumber,
              typeIdentification:employeData.typeIdentification,
              sexo:employeData.sexo,
              nationality:employeData.nationality,
              studyLevel:employeData.studyLevel,
              birthDate:employeData.birthDate,
              neighborhood:employeData.neighborhood,
              languaje: employeData.languaje,
              first_job:employeData.first_job,

          });
        //   // Tus datos actualizados aquí
        //   return updateResponse.data;
        } catch (updateError) {
          console.error(updateError);
          throw updateError;
        }
      
    }
  }else{
    try {
      
        await instance.get(`/companies/${userId}`);
        await instance.put(`/companies/${employeData.id}`, {
          name: employeData.name, 
          phone: employeData.phone,
          description: employeData.description,
          country: employeData.country,
          city: employeData.city,
          department: employeData.departmen,
          adress: employeData.adress,
          photo: employeData.photo,
          user_id: userId,
          nit: employeData.nit ,
          check_digit: employeData.check_digit,
          contact_name: employeData.contact_name,
          contact_position: employeData.contact_position,
          contact_number: employeData.contact_number
        });
      // if (response.data !== null && response.data !== ''){
        
      
    } catch (error) {
      // Puedes verificar el código de estado o el mensaje de error para saber si debes actualizar
        try {
        
            await instance.post('/companies', { 
              name: employeData.name, 
              phone: employeData.phone,
              description: employeData.description,
              country: employeData.country,
              city: employeData.city,
              department: employeData.departmen,
              adress: employeData.adress,
              photo: employeData.photo,
              user_id: userId,
              nit: employeData.nit ,
              check_digit: employeData.check_digit,
              contact_name: employeData.contact_name,
              contact_position: employeData.contact_position,
              contact_number: employeData.contact_number

          });
        // Tus datos actualizados aquí
        //   return updateResponse.data;
        } catch (updateError) {
          console.error(updateError);
          throw updateError;
        }
      
    }
  }
};

export const postDocumento = async (name, tipo) => {
  var userId = localStorage.getItem("id");
  userId = parseInt(userId);
  try {
    const response = await instance.post('/documents', {
      name: name,
      type: tipo,
      user_id: userId
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobs = async () => {
  try {
    const response = await instance.get(`/job_offers`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobs_id = async (id) => {
  try {
    const response = await instance.get(`/job_offers/${id}`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobs_id2 = async (id) => {
  try {
    const response = await instance.get(`/job_offers2/${id}`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobOfferTools = async (id) => {
  try {
    const response = await instance.get(`/job_offer_tools/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const get_saved_offers_by_employee_id = async (id) => {
  try {
    const response = await instance.get(`/job_offers/saved/${id}`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const get_saved_offers_by_job_employee = async (job_offer_id,employee_id) => {
  try {
    const response = await instance.get(`/job_offers/filtered/${job_offer_id}/${employee_id}`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const delete_saved_offers_by_job_employee = async (job_offer_id,employee_id) => {
  try {
    const response = await instance.delete(`/job_offers/unsave/${job_offer_id}/${employee_id}`);

    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveJobOffer = async (jobOfferData) => {
  try {
    const response = await instance.post('/job_offers/save-job-offer', jobOfferData);
    return response.data;
  } catch (error) {
    // Manejar el error adecuadamente
    if (error.response) {
      // El servidor respondió con un estado diferente de 2xx
      console.error('Error:', error.response.data);
      
      throw new Error(error.response.data.detail);
    } else if (error.request) {
      // La solicitud se hizo pero no se recibió respuesta
      console.error('Error:', error.request);
      throw new Error('No response received from the server');
    } else {
      // Ocurrió un error al configurar la solicitud
      console.error('Error:', error.message);
      throw new Error('Error configuring the request');
    }
  }
};

export const getCategoryById = async (id) => {
  try {
    const response = await instance.get(`/categories/${id}`);

    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postApplications = async (employee, job_offer) => {
  console.log("emp"+employee);
  console.log("joboffer"+job_offer);
  try {
    const response = await instance.post('/applications', {
      employee_id: employee,
      job_offer_id: job_offer // Asegúrate de que esto sea un número, ya que en el JSON se muestra como 0
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getUserApplications = async (user_id) => {
  try {
    const response = await instance.get(`/applications/userApplication/${user_id}`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getJobOfferApplications = async (job_offer_id) => {
  try {
    const response = await instance.get(`/applications/offerApplication/${job_offer_id}`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getApplications = async () => {
  try {
    const response = await instance.get(`/applications`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postJobOffers = async (jobOfferData) => {
  if (isNaN(jobOfferData.category_id)||jobOfferData.category_id===''){
    jobOfferData.category_id = 1 
  } 
  try {
    const response = await instance.post('/job_offers', {
      title: jobOfferData.title,
      description: jobOfferData.description,
      salary: jobOfferData.salary,
      modality: jobOfferData.modality,
      category_id: jobOfferData.category_id,
      company_id: jobOfferData.company_id,
      experience: jobOfferData.experience,
      date: jobOfferData.date,
      city:jobOfferData.city,
      department:jobOfferData.department,
      languaje:jobOfferData.languaje,
      languaje_level: jobOfferData.languaje_level,
      required_vehicle:jobOfferData.required_vehicle,
      study_level:jobOfferData.study_level,
      contract_type:jobOfferData.contract_type,
      available_time: jobOfferData.available_time,
      technology_tools: jobOfferData.technology_tools,
      status: jobOfferData.status,
      suspended: jobOfferData.suspended
    });
    
    console.log(response);
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putJobOffers = async (jobOfferData,ids) => {
 
  try {
    const response = await instance.put(`/job_offers/${ids}`, {
      title: jobOfferData.title,
      description: jobOfferData.description,
      salary: jobOfferData.salary,
      modality: jobOfferData.modality,
      category_id: jobOfferData.category_id,
      company_id: jobOfferData.company_id,
      experience: jobOfferData.experience,
      date: jobOfferData.date,
      city:jobOfferData.city,
      department:jobOfferData.department,
      languaje:jobOfferData.languaje,
      languaje_level: jobOfferData.languaje_level,
      required_vehicle:jobOfferData.required_vehicle,
      study_level:jobOfferData.study_level,
      contract_type:jobOfferData.contract_type,
      available_time: jobOfferData.available_time,
      technology_tools: jobOfferData.technology_tools,
      status: jobOfferData.status,
      suspended: jobOfferData.suspended
    });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putJobOfferStatus = async (id,status) => {
 
  try {
    const response = await instance.put(`/job_offers/${id}/${status}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putJobOfferSuspendedStatus = async (id,status) => {
 
  try {
    const response = await instance.put(`/job_offers/suspend/${id}/${status}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCategory = async () => {
  try {
    const response = await instance.get(`/categories`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCompany = async (id) => {
  try {
    const response = await instance.get(`/companies/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getCompany2 = async (id) => {
  try {
    const response = await instance.get(`/companies2/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deletejoboffer = async (id) => {
  try {
    
    const response = await instance.delete(`/job_offers/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await instance.post(`/upload/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.filename;
  } catch (error) {
    throw error;
  }
};


export const downloadFile = async (filename) => {
  try {
    const response = await instance.get(`/download/${filename}`, {
      responseType: 'blob', // Para recibir datos binarios
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDocument = async (id) => {
  try {
    const response = await instance.get(`/documents/${id}`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDocument = async (filename) => {
  try {
    const response = await instance.delete(`/delete/${filename}`);
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteDocuments = async (id) => {
  try {
    const response = await instance.delete(`/documents/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export const downloadFile2 = async (filename) => {

  try {
    const response = await instance.get(`/download2/${filename}`, {
      responseType: 'blob', // Para recibir datos binarios
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteImg = async (filename) => {
  try {
    const response = await instance.delete(`/delete/${filename}`);
    return response.data.filename;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadFileImg = async (file) => {
  const formData = new FormData();
  formData.append('file', file);
  var ids = localStorage.getItem("id")

  try {
    const response = await instance.post(`/upload2/${ids}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data.filename;
  } catch (error) {
    throw error;
  }
};

export const getEducations = async (id) => {
  try {
    const response = await instance.get(`/educations2/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEducation = async (id) => {
  try {
    const response = await instance.get(`/educations/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postEducation = async (title, institution, start_date, end_date, user_id, certificate) => {
  
  try {
    const response = await instance.post('/educations', {
      title: title,
      institution: institution,
      start_date: start_date,
      end_date: end_date,
      user_id: user_id,
      certificate:certificate,
    });
    
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteEducation = async (id) => {
  try {
    const response = await instance.delete(`/educations/${id}`);
    return response.data.filename;
  } catch (error) {
    console.error(error);
    throw error;
  }
};



export const putEducation = async (title, institution, start_date, end_date, user_id, id, certificate) => {

  try {
    const response = await instance.put(`/educations/${id}`, {
      title: title,
      institution: institution,
      start_date: start_date,
      end_date: end_date,
      user_id: user_id,
      certificate:certificate,
    });
    
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getExperiences = async (id) => {
  try {
    const response = await instance.get(`/experiences2/${id}`);
    console.log("getExperiencia " + response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getExperience = async (id) => {
  try {
    const response = await instance.get(`/experiences/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const postExperience = async (company, post, description, start_date,end_date, user_id, jobCertificate, informalJob, ownVehicle) => {
  
  try {
    const response = await instance.post('/experiences', {
      company: company,
      post: post,
      description :  description ,
      start_date: start_date,
      end_date: end_date,
      user_id: user_id,
      certificate: jobCertificate,
      informal_job: informalJob,
      own_vehicle: ownVehicle
    });
    
    
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error('Error de servidor:', error.response.data);
      console.error('Código de estado:', error.response.status);
    } else if (error.request) {
      console.error('Error de red, no se recibió respuesta');
    } else {
      console.error('Error durante la configuración de la solicitud:', error.message);
    }
    throw error;
  }
};

export const deleteExperience = async (id) => {
  try {
    const response = await instance.delete(`/experiences/${id}`);
    return response.data.filename;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const putExperience = async  (company, post, description, start_date, end_date, user_id, id, jobCertificate, informalJob, ownVehicle) => {

  try {
    const response = await instance.put(`/experiences/${id}`, {
      company: company,
      post: post,
      description:description,
      start_date: start_date,
      end_date: end_date,
      user_id: user_id,
      certificate: jobCertificate,
      informal_job: informalJob,
      own_vehicle: ownVehicle
    });
    
    console.log("response " + response);
    
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const send_newsletter = async (user_email)=>{
  try{
    const response = await instance.get(`/send_newsletter/${user_email}`)
    return response.data;

  } catch(error){
    console.log(error)
    throw error;
  }

}

export const forgot_pass = async (user_email)=>{
  try{
    const response = await instance.get(`/forgot_password/${user_email}`)
    return response.data;

  } catch(error){
    console.log(error)
    throw error;
  }

}