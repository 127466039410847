import React from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
// import Section from "./Section";

const CompanyDetails = () => {
  document.title =
    "Avaza Empleos | Detalles de la empresa";
  return (
    <React.Fragment>
      {/* <Section /> */}
      <section className="section">
        <Container>
          <Row>
            <LeftSideContent />
            <RightSideContent />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CompanyDetails;
