import React, { useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { getJobs_id2, postApplications, getEmployees, getUserApplications, getJobOfferTools } from "../../../services/userservice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const RightSideContent = () => {
  const job = localStorage.getItem("jobs");
  const [jobs, setJobs] = useState("");
  const [jobOfferTools, setJobOfferTools] = useState([]);
  const [userId, setUserId] = useState('');
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [salary, setSalary] = useState("");
  const [modality, setModality] = useState("");
  const [experience, setExperience] = useState("");
  const [createdAt, setCreatedAt] = useState("");
  const [buttonState, setButtonState] = useState("apply"); // Estado del botón

  const technologyToolsOptions = [
    { id: 1, name: "No aplica" },
    { id: 2, name: "Software contable" },
    { id: 3, name: "Software de inventarios" },
    { id: 4, name: "ERP" },
    { id: 5, name: "Paquete office" },
    { id: 6, name: "Caja registradora (pos)" }
  ];

  useEffect(() => {
    const user = localStorage.getItem("id");
    const employeeId = localStorage.getItem("employeeId");
  
    setUserId(user);
  
    getJobs_id2(job)
      .then(data => setJobs(data))
      .catch(error => toast.error(error));
    if (employeeId) {
      getUserApplications(employeeId)
        .then(data => {
          if (isJobApplied(data, job)) {
            setButtonState("submitted");
          }
        })
        .catch(error => toast.error(error));
    }

    getJobOfferTools(job)
      .then(data => {
        const toolNames = data.map(tool => {
          const toolOption = technologyToolsOptions.find(option => option.id === tool.technology_tool_id);
          return toolOption ? toolOption.name : 'Desconocido';
        });
        setJobOfferTools(toolNames);
      })
      .catch(error => toast.error(error));
  }, [job]);

  useEffect(() => {
    setTitle(jobs.title);
    setDescription(jobs.description);
    setSalary(jobs.salary);
    setModality(jobs.modality);
    setExperience(jobs.experience);
    setCreatedAt(jobs.created_at);
  }, [jobs]);

  const isJobApplied = (applications, jobId) => {
    return applications.some(application => application.job_offer_id === parseInt(jobId));
  };

  const handleSubmit = async (selected, e) => {
    e.preventDefault();
    if (userId === null) {
      toast.error("Por favor inicie sesión para realizar la postulación");
      return;
    }
    try {
      const employees = await getEmployees(userId);
      const employee = employees.id;
      const job_offer = selected;

      await postApplications(employee, job_offer);

      toast.success("Postulación enviada");
      setButtonState("submitted");

      return true;
    } catch (err) {
      toast.error("Error al enviar la postulación");
    }
  };

  return (
    <React.Fragment>
      <style>
        {`
          .btn-success {
            transition: all 0.3s ease;
          }
          .requisitos p {
            font-size: 0.77rem; 
          }
        `}
      </style>
      <ToastContainer />
      <div className="side-bar ms-lg-4 pt-5">
        <Card className="job-overview">
          <CardBody className="p-4">
            <h6 className="fs-17">Descripción General</h6>
            <ul className="list-unstyled mt-4 mb-0">
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-user icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Nombre del Cargo</h6>
                    <p className="text-muted mb-0">{title}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-star-half-alt icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Experiencia</h6>
                    <p className="text-muted mb-0">{experience}</p>
                  </div>
                </div>
              </li>
              {/* <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-location-point icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Ubicacion</h6>
                    <p className="text-muted mb-0"> Cali - Colombia</p>
                  </div>
                </div>
              </li> */}
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-usd-circle icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Oferta Salarial</h6>
                    <p className="text-muted mb-0">${salary}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-graduation-cap icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3 requisitos">
                    <h6 className="fs-14 mb-2">Requisitos</h6>
                    <ul className="list-unstyled mb-0">
                      <li>
                        <div className="d-flex mt-2">
                          <div className="ms-3">
                            <p className="text-muted mb-0">Disponibilidad de tiempo: {jobs.available_time}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex mt-2">
                          <div className="ms-3">
                            <p className="text-muted mb-0">Nivel de estudio culminado: {jobs.study_level}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex mt-2">
                          <div className="ms-3">
                            <p className="text-muted mb-0">Medio de transporte propio:  {jobs.required_vehicle ? "Requiere vehiculo propio" : "No requiere vehiculo propio"}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex mt-2">
                          <div className="ms-3">
                            <p className="text-muted mb-0">Idiomas: {jobs.languaje}</p>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex mt-2">
                          <div className="ms-3">
                            <p className="text-muted mb-0">Herramientas de trabajo: {jobOfferTools.join(", ")}</p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-building icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Modalidad</h6>
                    <p className="text-muted mb-0">{modality}</p>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex mt-4">
                  <i className="uil uil-history icon bg-primary-subtle text-primary"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Fecha de Publicación</h6>
                    <p className="text-muted mb-0">{new Date(createdAt).toLocaleDateString()}</p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-3">
              {buttonState === "apply" && (
                <Link
                  to="#applyNow"
                  onClick={(e) => handleSubmit(job, e)}
                  className="btn btn-primary btn-hover w-100 mt-2"
                >
                  Aplicar Ahora <i className="uil uil-arrow-right"></i>
                </Link>
              )}
              {buttonState === "submitted" && (
                <div>
                  <button
                    className="btn btn-success btn-hover w-100 mt-2"
                    disabled
                  >
                    Postulación enviada
                  </button>
                  <Link
                    to="/jobgrid"
                    className="btn btn-secondary btn-sm w-100 mt-2"
                  >
                    Ver Más Ofertas
                  </Link>
                </div>
              )}
              {/* <Link
                to="/bookmarkjobs"
                className="btn btn-soft-warning btn-hover w-100 mt-2"
              >
                <i className="uil uil-bookmark"></i> Añadir a Favoritos
              </Link> */}
            </div>
          </CardBody>
        </Card>

        {/* <Card className="company-profile mt-4">
          <CardBody className="p-4">
            <div className="text-center">
              <img src={jobImages2} alt="" className="img-fluid rounded-3" />

              <div className="mt-4">
                <h6 className="fs-17 mb-1">Jobcy Technology Pvt.Ltd</h6>
                <p className="text-muted">Desde Julio 2017</p>
              </div>
            </div>
            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="uil uil-phone-volume text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Tel:</h6>
                    <p className="text-muted fs-14 mb-0">+57 315 3214556</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-envelope text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-14 mb-0">
                      info@gmail.com
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-globe text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Sitio Web</h6>
                    <p className="text-muted fs-14 text-break mb-0">
                      www.ejemplo.com
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="uil uil-map-marker text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Ubicacion</h6>
                    <p className="text-muted fs-14 mb-0">
                      Cali - Colombia
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4">
              <Link
                to="/companydetails"
                className="btn btn-primary btn-hover w-100 rounded"
              >
                <i className="mdi mdi-eye"></i> Ver Perfil
              </Link>
            </div>
          </CardBody>
        </Card> */}

        {/* <div className="mt-4">
          <h6 className="fs-16 mb-3">Ubicacion</h6>
          <iframe
            title="maps"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.15830869428!2d-74.119763973046!3d40.69766374874431!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sin!4v1628067715234!5m2!1sen!2sin"
            style={{ width: `100%`, height: `250` }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div> */}
        <div
          className="modal fade"
          id="applyNow"
          tabIndex="-1"
          aria-labelledby="applyNow"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            {/* <Modal isOpen={modal} toggle={openModal} centered>
              <ModalBody className="modal-body p-5">
                <div className="text-center mb-4">
                  <h5 className="modal-title" id="staticBackdropLabel">
                    Aplica a este empleo
                  </h5>
                </div>
                <div className="position-absolute end-0 top-0 p-3">
                  <button
                    type="button"
                    onClick={openModal}
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="mb-3">
                  <Label for="nameControlInput" className="form-label">
                    Nombre Completo
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="nameControlInput"
                    placeholder="Nombre Completo"
                  />
                </div>
                <div className="mb-3">
                  <Label for="emailControlInput2" className="form-label">
                    Email
                  </Label>
                  <Input
                    type="email"
                    className="form-control"
                    id="emailControlInput2"
                    placeholder="Email"
                  />
                </div>
                <div className="mb-3">
                  <Label for="messageControlTextarea" className="form-label">
                    Mesage
                  </Label>
                  <textarea
                    className="form-control"
                    id="messageControlTextarea"
                    rows="4"
                    placeholder="Escribe aquí tu mensaje..."
                  ></textarea>
                </div>
                <div className="mb-4">
                  <Label className="form-label" for="inputGroupFile01">
                    Adjuntar CV
                  </Label>
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile01"
                  />
                </div>
                <button type="submit" className="btn btn-primary w-100">
                  Enviar
                </button>
              </ModalBody>
            </Modal> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RightSideContent;
