import React from "react";
import Routes from "./Routes/index";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//import Custom Style scss
import "./assets/scss/themes.scss";
function App() {
  return (
    
    <React.Fragment>
      <Routes />
      <ToastContainer />
    </React.Fragment>
    
  );
}

export default App;
