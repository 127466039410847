import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Input, Label, Row, Form } from "reactstrap";
import { postJobOffers, getCategory, getCompany, getJobs_id2, putJobOffers, getJobOfferTools } from "../../../services/userservice";
import { languajeList, studyLevelList, contractTypeList, availableTimeList } from "../MyProfile/FieldList";
import { departmentCityOptions } from "../../Home/SubSection/DepartmentCityOptions";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const JobPostEdit = () => {
  const [job, setJob] = useState({}); 
  const [jobTools, setJobTools] = useState([]); 
  const [loading, setLoading] = useState(true); // Estado de carga
  var ids = localStorage.getItem('job_id');
  const [crear, setCrear] = useState(false);
  const [category_id, setCategory_id] = useState("");
  
  useEffect(() => {
    if (ids === null || ids === "0") {
      setCrear(true);
      setCategory_id("");
      setLoading(false); // No hay datos que cargar
    } else {
      Promise.all([getJobs_id2(ids), getJobOfferTools(ids)])
        .then(([jobData, toolsData]) => {
          setJob(jobData);
          const technologyToolIds = toolsData.map(obj => obj.technology_tool_id);
          setJobTools(technologyToolIds);
          setLoading(false); // Datos cargados
        })
        .catch(error => {
          console.error(error);
          setLoading(false); // Error al cargar datos
        });
    }
  }, [ids]);

  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [salary, setSalary] = useState(1300000);
  const [modality, setModality] = useState("");
  const [company_id, setCompany] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [experience, setExperience] = useState("");
  const [date, setDate] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [cityOptions, setCityOptions] = useState([]);
  const [city, setCity] = useState("");
  const [languaje, setLanguaje] = useState("");
  const [languajeLevel, setLanguajeLevel] = useState("Seleccione un nivel");
  const [languajeLevelDisabled, setLanguajeLevelDisabled] = useState(false);
  const [vehicleRequired, setVehicleRequired] = useState(false);
  const [studyLevel, setStudyLevel] = useState("Seleccione una opción");
  const [contractType, setContractType] = useState("");
  const [availableTime, setAvailableTime] = useState("");
  const [technologyTools, setTechnologyTools] = useState([]);

  const technologyToolsOptions = [
    { id: 1, name: "No aplica" },
    { id: 2, name: "Software contable" },
    { id: 3, name: "Software de inventarios" },
    { id: 4, name: "ERP" },
    { id: 5, name: "Paquete office" },
    { id: 6, name: "Caja registradora (pos)" }
  ];

  const modalityList = ['Presencial','Hibrido','Remoto'];
  const languajeLevelList = ['Basico','Intermedio','Avanzado o Nativo'];
  const experienceList = ['Sin Experiencia','Menos de un año','De uno a dos años','De dos a tres años','Más de tres años'];
  
  var id = localStorage.getItem("id");
  
  useEffect(() => {
    getCompany(id)
      .then(data => setCompany(data.id))
      .catch(error => console.error(error));
  }, [id]);

  useEffect(() => {
    getCategory()
      .then(data => setCategorias(data))
      .catch(error => console.error(error));
  }, []);
  
  useEffect(() => {
    if (!loading) {
      setDescription(job.description || "");
      setTitle(job.title || "");
      setSalary(job.salary || "");
      setModality(job.modality || "");
      setCategory_id(job.category_id || "");
      setCompany(job.company_id || "");
      setExperience(job.experience || "");
      setDate(job.date || "");
      setCity(job.city || "");
      setLanguaje(job.languaje || "");
      setLanguajeLevel(job.languaje_level || "");
      setSelectedDepartment(job.department || "");
      setVehicleRequired(job.required_vehicle || false);
      setStudyLevel(job.study_level || "Seleccione una opción");
      setContractType(job.contract_type || "");
      setAvailableTime(job.available_time || "");
      setTechnologyTools(jobTools || []);
    }
  }, [job, jobTools, loading]);

  useEffect(() => {
    const department = departmentCityOptions.find(dept => dept.departamento === selectedDepartment);
    if (department) {
      setCityOptions(department.ciudades);
    } else {
      setCityOptions([]);
    }
  }, [selectedDepartment]);

  const navigate = useNavigate();

  const formatCurrency = (value) => {
    if (!value) return "";
    const numberValue = parseInt(value.replace(/[^0-9]/g, ''), 10);
    if (isNaN(numberValue)) return "";
    return `$${numberValue.toLocaleString('es-CO')}`;
  };

  const handleSalaryChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatCurrency(value);
    setSalary(formattedValue);
  };

  const handleTechnologyToolsChange = (e) => {
    const { value, checked } = e.target;
    const id = parseInt(value);
    if (checked) {
      setTechnologyTools((prev) => [...prev, id]);
    } else {
      setTechnologyTools((prev) => prev.filter((tool) => tool !== id));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (category_id === "") {
      toast.error("Por favor, seleccione una categoría válida.");
      return;
    }
    const numericSalary = parseInt(salary.replace(/[^0-9]/g, ''), 10);
    if (numericSalary < 1300000) {
      toast.error("El salario no puede ser menor a 1,300,000 COP.");
      return;
    }

    if (technologyTools.length === 0) {
      toast.error("Por favor, seleccione al menos una herramienta tecnológica.");
      return;
    }

    try {
      let jobOfferData = {
        title: title,
        description: description,
        salary: numericSalary,
        modality: modality,
        category_id: category_id,
        company_id: company_id,
        experience: experience,
        date: date,
        city: city,
        department: selectedDepartment,
        languaje: languaje,
        languaje_level: languajeLevel,
        required_vehicle: vehicleRequired,
        study_level: studyLevel,
        contract_type: contractType,
        available_time: availableTime,
        technology_tools: technologyTools,
        status:job.status || true,
        suspended:job.suspended || false
      };
      if (crear) {
        await postJobOffers(jobOfferData);
      } else {
        await putJobOffers(jobOfferData, ids);
      }

      localStorage.setItem('offer', 0);
      navigate('/managejobs');
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectedLanguaje = (e) => {
    let languaje = e.target.value;
    if (languaje === 'Seleccione un idioma') {
      setLanguajeLevelDisabled(false);
      setLanguajeLevel("");
    } else {
      setLanguajeLevelDisabled(true);
    }
    setLanguaje(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>; 
  }

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="bg-primary-subtle text-primary p-3">
                <h5 className="mb-0 fs-17">¡Publica una nueva oferta de empleo!</h5>
              </div>
            </Col>
          </Row>
          <Form action="/" className="auth-form" onSubmit={handleSubmit}>
            <div className="job-post-content box-shadow-md rounded-3 p-4">
              <Row className="row">
                <Col lg={12}>
                  <div className="mb-4">
                    <Label htmlFor="jobtitle" className="form-label">
                      Cargo Solicitado
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="jobtitle"
                      placeholder="Titulo"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <Label htmlFor="jobdescription" className="form-label">
                      Descripcion
                    </Label>
                    <textarea
                      className="form-control"
                      id="jobdescription"
                      rows="3"
                      placeholder="Descripcion de la oferta de empleo" 
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="departmen" className="form-label">
                      Departamento
                    </Label>
                    <select
                      className="form-select"
                      data-trigger
                      required
                      name="choices-single-location"
                      id="choices-single-location"
                      aria-label="Default select example"
                      value={selectedDepartment}
                      onChange={(e) => setSelectedDepartment(e.target.value)}
                    >
                      {departmentCityOptions.map((departamento) => (
                        <option key={departamento.id} value={departamento.departamento}>
                          {departamento.departamento}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="city" className="form-label">
                      Ciudad
                    </Label>
                    <select
                      required
                      className="form-select"
                      id="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    >
                      {cityOptions.map((ciudad, index) => (
                        <option key={index} value={ciudad}>
                          {ciudad}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4">
                    <label htmlFor="choices-single-categories" className="form-label">
                      Categoria
                    </label>
                    <select
                      className="form-select"
                      onChange={(e) => setCategory_id(e.target.value)}
                      value={category_id}
                    >
                      <option value="">Seleccione una opción</option> {/* Opción por defecto no válida */}
                      {categorias.map((categoria, index) => (
                        <option key={index} value={categoria.id}>{categoria.category_name}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4">
                    <label htmlFor="jobtype" className="form-label">
                      Modalidad
                    </label>
                    <select
                      className="form-select"
                      id="jobtype"
                      onChange={(e) => setModality(e.target.value)}
                      value={modality}
                    >
                      <option value="">Seleccione una opción</option> {/* Opción por defecto no válida */}
                      {modalityList.map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="languages" className="form-label">
                      Idiomas
                    </Label>
                    <select
                      required
                      className="form-select"
                      id="languaje"
                      value={languaje}
                      onChange={(e) => handleSelectedLanguaje(e) }
                    >
                      {languajeList.map((lenguaje) => (
                        <option key={lenguaje.valor} value={lenguaje.valor}>
                          {lenguaje.valor}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Label htmlFor="languages" className="form-label">
                      Nivel de comprensión
                    </Label>
                    <select
                      required
                      className="form-select"
                      id="languajeLevel"
                      disabled={!languajeLevelDisabled}
                      value={languajeLevel}
                      onChange={(e) => setLanguajeLevel(e.target.value)}
                    >
                      <option value="">Seleccione una opción</option> {/* Opción por defecto no válida */}
                      {languajeLevelList.map((index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4">
                    <label htmlFor="salary" className="form-label">
                      Salario ($ COP)
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      id="salary"
                      placeholder="Salario ($ COP)"
                      value={salary}
                      required
                      onChange={handleSalaryChange}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-4">
                    <label htmlFor="skills" className="form-label">
                      Tiempo de Experiencia{" "}
                    </label>
                    <select
                      className="form-select"
                      id="skills"
                      onChange={(e) => setExperience(e.target.value)}
                      value={experience}
                    >
                      <option value="">Seleccione una opción</option> {/* Opción por defecto no válida */}
                      {experienceList.map((index) => (
                        <option key={index} value={index}>{index}</option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col lg={6}>
                  <label htmlFor="certificate" className="form-label">
                    Requiere medio de transporte propio
                  </label>
                  <div className="mb-4 text-center">
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <Input
                        type="checkbox"
                        style={ !vehicleRequired ? {border:"2px solid #e1e1e1", width:"22px", height:"22px"} : {width:"20px", height:"20px"}}
                        checked={vehicleRequired}
                        onChange={(e) => setVehicleRequired(e.target.checked)}
                        id="vehicleRequired"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      Estudios Academicos
                    </label>
                    <select
                      className={`form-select ${errors.studyLevel ? "is-invalid" : ""}`}
                      data-trigger
                      name="choices-single-location"
                      id="choices-single-location"
                      aria-label="Default select example"
                      value={studyLevel}
                      required
                      onChange={(e) => setStudyLevel(e.target.value)}
                    >
                      {studyLevelList.map((academy) => (
                        <option key={academy.study} value={academy.study}>
                          {academy.study}
                        </option>
                      ))}
                    </select>
                    {errors.studyLevel && <div className="invalid-feedback">{errors.studyLevel}</div>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      Tipo de Contrato
                    </label>
                    <select
                      className={`form-select ${errors.contractType ? "is-invalid" : ""}`}
                      data-trigger
                      name="choices-single-location"
                      id="choices-single-location"
                      aria-label="Default select example"
                      value={contractType}
                      required
                      onChange={(e) => setContractType(e.target.value)}
                    >
                      <option value="">Seleccione una opción</option>
                      {contractTypeList.map((index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                    {errors.studyLevel && <div className="invalid-feedback">{errors.contractType}</div>}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="firstName" className="form-label">
                      Disponibilidad de tiempo
                    </label>
                    <select
                      className={`form-select ${errors.availableTime ? "is-invalid" : ""}`}
                      data-trigger
                      name="choices-single-location"
                      id="choices-single-location"
                      aria-label="Default select example"
                      value={availableTime}
                      required
                      onChange={(e) => setAvailableTime(e.target.value)}
                    >
                      <option value="">Seleccione una opción</option>
                      {availableTimeList.map((index) => (
                        <option key={index} value={index}>
                          {index}
                        </option>
                      ))}
                    </select>
                    {errors.studyLevel && <div className="invalid-feedback">{errors.availableTime}</div>}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <label htmlFor="lastdate" className="form-label">
                      Fecha límite de solicitud
                    </label>
                    <Input type="date" className="form-control" value={date} onChange={(e) => setDate(e.target.value)} id="lastdate" />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-4">
                    <label htmlFor="technologyTools" className="form-label">
                      Herramienta tecnológica
                    </label>
                    <div>
                      {technologyToolsOptions.map((tool) => (
                        <div key={tool.id} className="form-check">
                          <Input
                            type="checkbox"
                            className="form-check-input"
                            id={`technologyTool${tool.id}`}
                            value={tool.id}
                            checked={technologyTools.includes(tool.id)}
                            onChange={handleTechnologyToolsChange}
                          />
                          <Label className="form-check-label" htmlFor={`technologyTool${tool.id}`}>
                            {tool.name}
                          </Label>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="d-flex flex-wrap align-items-start gap-1 justify-content-end">
                    <Link to="/managejobs" className="btn btn-success">
                      Atras
                    </Link>
                    <button type="submit" className="btn btn-primary">
                      Publicar <i className="mdi mdi-send"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          </Form>
        </Container>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
};

export default JobPostEdit;
