import React from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
const rol = parseInt(localStorage.getItem("rol"));

const MyProfile = () => {
  document.title = "Avanza Empleos | Mi perfil";
  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          { rol === 1 || '1' ? 
            <></> :
            <p style={{marginTop:"-5%", marginBottom:"5%" }}>
              <strong>
                Por favor registre y actualice su información, así su hoja de vida y perfil será evaluada para continuar el proceso de entrevista y selección de personal. La información suministrada en su perfil permite que su postulación en la oferta laboral sea más atractiva para la empresa cliente.
              </strong>
            </p>
          }
          <Row>
            <LeftSideContent />
            <RightSideContent />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default MyProfile;
